import { LicenseDetails } from 'interfaces/license-details';

import { LicenseUsage } from 'interfaces/license-usage';

import { api } from './api';

/**
 * Gets the license details.
 * @returns A `Promise` that emits the license details; `null` if there is no license.
 */
export function getLicenseDetails() {
  return api.get<LicenseDetails>(`/license`);
}

/**
 * Gets a new license request code.
 * @returns A `Promise` that emits a new request code.
 */
export function getLicenseRequestCode() {
  return api.get<string>(`license/request-code`);
}

/**
 * Gets license usage details.
 * @returns A `Promise` that emits licensing usage details.
 */
export function getLicenseUsage() {
  return api.get<LicenseUsage>(`license/usage`);
}

/**
 * Registers and applies a license key.
 * @param licenseKey The license key to register/apply.
 * @returns `true` if license key was succesfully applied; `false` otherwise.
 */
export function registerLicense(licenseKey: string) {
  return api.put<boolean>(`/license`, licenseKey, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}
