import { formatDistance, parseISO } from 'date-fns';

import { useLicense } from 'app';

import {
  BaseLicensingAlert,
  BaseLicensingAlertProps,
  LicensingAlertProps,
} from './BaseLicensingAlert';

export function LicenseExpiringSoonAlert(
  props: Omit<LicensingAlertProps, keyof BaseLicensingAlertProps>
) {
  const { isLicensedButExpiringSoon, licenseDetails } = useLicense();

  if (!isLicensedButExpiringSoon) {
    return null;
  }

  return (
    <BaseLicensingAlert
      colorScheme="warning"
      childrenForAll={
        <>
          License will expire in{' '}
          <strong>{formatDistance(parseISO(licenseDetails.expiration), new Date())}</strong>. Some
          functionality will be disabled when this occurs.&nbsp;
        </>
      }
      {...props}
    ></BaseLicensingAlert>
  );
}
