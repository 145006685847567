import { motion } from 'framer-motion';
import { ReactNode, forwardRef } from 'react';
import { FiX } from 'react-icons/fi';

import { IconButton } from 'components/button';
import { Modal, ModalProps } from 'components/modal';
import { Slide } from 'components/transitions';
import { styled } from 'stitches';

import { DrawerContextProvider, useDrawerContextProvider } from './DrawerContext';
import { DrawerDismiss } from './DrawerDismiss';

export const DrawerRoot = styled(Modal, {
  zIndex: '$3',
});

const DrawerBox = styled('div', {
  backgroundColor: '#ffffff',

  boxShadow: '$xl',

  position: 'fixed',
  height: '100%',
  top: 0,

  display: 'flex',
  flexDirection: 'row',
  flex: '1 0 auto',

  overflow: 'hidden',
  zIndex: '$3',

  WebkitOverflowScrolling: 'touch',
  outline: 0,

  maxWidth: '480px',
});

const AnimatedDrawerBox = motion(DrawerBox);

const DrawerActions = styled('div', {
  flexShrink: 0,
  padding: '$4 $4',
});

const DrawerContainer = styled('div', {
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
});

export const DrawerContent = styled('div', {
  WebkitOverflowScrolling: 'touch',
  overflowY: 'auto',
  padding: '$5 $2',
  flexGrow: '1',
});

export const DrawerHeader = styled('div', {
  flex: '0 0 auto',
  padding: '$5 $2',
});

export type DrawerProps = Omit<ModalProps, 'children'> & {
  children?: ReactNode;
};

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(function Drawer(props, forwardedRef) {
  const { children, open, onClose, ...rest } = props;

  const context = useDrawerContextProvider({
    open,
    onClose,
  });

  return (
    <DrawerContextProvider value={context}>
      <DrawerRoot ref={forwardedRef} open={open} onClose={onClose} {...rest}>
        <Slide in direction="left">
          {(motionProps) => (
            <AnimatedDrawerBox
              // TODO: Remove any cast
              {...(motionProps as any)}
            >
              <DrawerActions>
                <DrawerDismiss>
                  <IconButton>
                    <FiX />
                  </IconButton>
                </DrawerDismiss>
              </DrawerActions>

              <DrawerContainer>{children}</DrawerContainer>
            </AnimatedDrawerBox>
          )}
        </Slide>
      </DrawerRoot>
    </DrawerContextProvider>
  );
});
