import { useIsAdmin } from 'auth';
import { Alert, AlertProps } from 'components/alert';
import { Box } from 'components/box';
import { Flex } from 'components/flex';
import { Link } from 'components/link';

export const LICENSING_PATH: string = '/admin/configuration/licensing';

export type BaseLicensingAlertProps = {
  childrenForAll: JSX.Element;
  childrenForNonAdmin?: JSX.Element;
  childrenForAdmin?: JSX.Element;
};

export type LicensingAlertProps = BaseLicensingAlertProps & AlertProps;

export function BaseLicensingAlert(props: LicensingAlertProps) {
  const {
    childrenForAll,
    childrenForAdmin = (
      <>
        <Link to={LICENSING_PATH}>Renew license</Link>.
      </>
    ),
    childrenForNonAdmin = null,
    ...alertProps
  } = props;

  const isAdmin = useIsAdmin();

  return (
    <>
      <Alert {...alertProps}>
        <Flex direction="row" justify="space-between" gap="12">
          <Box>
            {childrenForAll}
            {isAdmin ? childrenForAdmin : childrenForNonAdmin}
          </Box>
        </Flex>
      </Alert>
    </>
  );
}
