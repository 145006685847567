import { KeyboardEvent, MouseEvent, ReactNode, forwardRef } from 'react';

import { Flex, FlexItem } from 'components/flex';
import { useMergedRefs } from 'hooks';

import { useAccordionContext, useAccordionPanelContext } from './AccordionContext';

export type AccordionPanelHeaderProps = {
  children?: ReactNode;
};

export const AccordionPanelHeader = forwardRef<HTMLDivElement, AccordionPanelHeaderProps>(
  function AccordionPanelHeader(props, forwardedRef) {
    const { children } = props;

    const { setFocusedIndex } = useAccordionContext();
    const { isOpen, index, descendantManager, headerId, contentId, register, setOpen } =
      useAccordionPanelContext();

    const handleRef = useMergedRefs(forwardedRef, register);

    const handleClick = (event?: MouseEvent) => {
      setOpen(!isOpen);
      setFocusedIndex(index);
    };

    const handleFocus = () => {
      setFocusedIndex(index);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter': {
          event.preventDefault();
          handleClick();
          break;
        }

        case 'ArrowDown': {
          descendantManager.getNextEnabled(index)?.node.focus({ preventScroll: true });
          break;
        }

        case 'ArrowUp': {
          descendantManager.getPreviousEnabled(index)?.node.focus({ preventScroll: true });
          break;
        }

        case 'Home': {
          descendantManager.getFirstEnabled()?.node.focus({ preventScroll: true });
          break;
        }

        case 'End': {
          descendantManager.getLastEnabled()?.node.focus({ preventScroll: true });
          break;
        }
      }
    };

    return (
      <Flex
        ref={handleRef}
        id={headerId}
        tabIndex={0}
        aria-expanded={isOpen}
        aria-controls={contentId}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      >
        <FlexItem grow css={{ cursor: 'pointer', padding: '$3' }}>
          {children}
        </FlexItem>
      </Flex>
    );
  }
);
