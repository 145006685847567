import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as applications from 'api/applications';
import {
  Application,
  ApplicationId,
  CreateApplicationPayload,
  OrganizationGroupId,
  TransferApplicationPayload,
  UpdateApplicationDashboardConfigurationPayload,
  UpdateApplicationGroupsPayload,
  UpdateApplicationPayload,
  UpdateApplicationResourcesPayload,
} from 'interfaces';

import { QueryOptions } from './types';

/**
 * Hook to get all applications.
 * @param options The query options.
 */
export function useApplicationsQuery(options?: QueryOptions<Application[]>) {
  const { data } = useQuery<Application[]>(
    ['applications'],
    () => applications.getApplications(),
    options
  );

  return {
    applications: data!,
  };
}

export function useApplicationQuery(applicationPath: string, options?: QueryOptions<Application>) {
  applicationPath = applicationPath?.toLocaleLowerCase();

  const queryClient = useQueryClient();
  const { data } = useQuery<Application>(
    ['applications', applicationPath],
    () => applications.getApplication(applicationPath),
    {
      ...options,
      initialData: () => {
        return queryClient
          .getQueryData<Application[]>('applications')
          ?.find((w) => w.slug.toLocaleLowerCase() === applicationPath);
      },
    }
  );

  return {
    application: data!,
  };
}

/**
 * Hook that returns a mutation used to create an application.
 * @returns The `UseMutationResult`.
 */
export function useCreateApplicationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateApplicationPayload) => applications.createApplication(payload),
    {
      onSuccess: async (application) => {
        queryClient.setQueryData(['applications', application.id], application);
        queryClient.setQueryData<Application[]>(['applications'], (prevValue) => [
          ...(prevValue || []),
          application,
        ]);

        await queryClient.invalidateQueries(['applications']);
      },
    }
  );
}

export type UseUpdateApplicationMutationPayload = UpdateApplicationPayload & {
  applicationId: ApplicationId;
};

export function useUpdateApplicationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ applicationId, ...payload }: UseUpdateApplicationMutationPayload) =>
      applications.updateApplication(applicationId, payload),
    {
      onSuccess: (application) => {
        queryClient.setQueryData<Application[]>(['applications'], (prevValue) => {
          return (
            prevValue?.map((x) => (x.id === application.id ? application : x)) || [application]
          );
        });
        queryClient.setQueryData<Application>(['applications', application.slug], application);
      },
    }
  );
}

export function useTransferApplication(applicationId: ApplicationId) {
  const mutation = useMutation(
    (payload: TransferApplicationPayload) =>
      applications.transferApplication(applicationId, payload),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

/**
 * Hook that queries the application resources.
 * @param applicationId The application ID.
 * @param organizationGroupId The organization group ID.
 * @returns The query data and info
 */
export function useApplicationResourcesQuery(
  applicationId: ApplicationId,
  organizationGroupId: OrganizationGroupId
) {
  const { data, ...queryInfo } = useQuery(
    ['applications', applicationId, 'resources', organizationGroupId],
    () => applications.getApplicationResources(applicationId, organizationGroupId)
  );

  return {
    applicationResources: data!,
    ...queryInfo,
  };
}

export type UseUpdateApplicationResourcesMutationPayload = UpdateApplicationResourcesPayload & {
  organizationGroupId: OrganizationGroupId;
};

/**
 * Hook that returns a mutation used to update an application's resources.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useUpdateApplicationResourcesMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ organizationGroupId, ...payload }: UseUpdateApplicationResourcesMutationPayload) =>
      applications.updateApplicationResources(applicationId, organizationGroupId, payload),
    {
      onSuccess: (result, { organizationGroupId }) => {
        queryClient.setQueryData(
          ['applications', applicationId, 'resources', organizationGroupId],
          result
        );
      },
    }
  );
}

/**
 * Hook that queries an application's assigned groups.
 * @param applicationId The application ID.
 * @returns The query result.
 */
export function useApplicationGroupsQuery(applicationId: ApplicationId) {
  const { data } = useQuery(['applications', applicationId, 'groups'], () =>
    applications.getApplicationGroups(applicationId)
  );

  return {
    applicationGroups: data!,
  };
}

/**
 * Hook that returns a mutation used to update an application's assigned groups.
 * @param applicationId The application ID.
 * @returns the `UseMutationResult`.
 */
export function useUpdateApplicationGroupsMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ['update-application-groups', { applicationId }],
    (payload: UpdateApplicationGroupsPayload) =>
      applications.updateApplicationGroups(applicationId, payload),
    {
      onSuccess: (groupIds) => {
        queryClient.setQueryData(['applications', applicationId, 'groups'], groupIds);
      },
    }
  );
}

/**
 * Hook used to query the application dashboard configuration.
 * @param applicationId The application ID.
 * @returns
 */
export function useApplicationDashboardConfigurationQuery(applicationId: ApplicationId) {
  const { data } = useQuery(['applications', applicationId, 'dashboard-configuration'], () =>
    applications.getApplicationDashboardConfiguration(applicationId)
  );

  return {
    dashboardConfiguration: data!,
  };
}

/**
 * Hook that returns a mutation used to update the application dashboard configuration.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useUpdateApplicationDashboardConfiguration(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateApplicationDashboardConfigurationPayload) =>
      applications.updateApplicationDashboardConfiguration(applicationId, payload),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'applications',
          applicationId,
          'dashboard-configuration',
        ]);
      },
    }
  );
}

/**
 * Hook that queries the application menu configuration.
 * @param applicationId The application ID.
 * @returns The menu and query data.
 */
export function useApplicationMenuQuery(applicationId: ApplicationId) {
  const { data, ...queryInfo } = useQuery(['applications', applicationId, 'menu'], () =>
    applications.getApplicationMenuConfiguration(applicationId)
  );

  return {
    menuConfiguration: data!,
    ...queryInfo,
  };
}

/**
 * Hook that queries the application menu configuration for a specific menu item.
 *
 * @param applicationId The application ID.
 * @param menuItemIdentifier The menu item identifier to retrieve.
 * @returns The menu item and query data.
 */
export function useApplicationMenuItemQuery(
  applicationId: ApplicationId,
  menuItemIdentifier: string
) {
  const { menuConfiguration: menu, ...queryInfo } = useApplicationMenuQuery(applicationId);
  const menuItem = menu?.find((entry) => entry.identifier === menuItemIdentifier);

  return {
    menuItem: menuItem,
    ...queryInfo,
  };
}
