import { OrganizationId } from 'interfaces';
import {
  ApplicationSuite,
  ApplicationSuiteId,
  CreateApplicationSuitePayload,
  UpdateApplicationSuitePayload,
} from 'interfaces/application-suite';

import { api } from './api';

/**
 * Gets the application suites for the specified organization.
 * @param organizationId The organization ID.
 * @returns A `Promise` that emits the application suites when the request has completed.
 */
export function getApplicationSuites(organizationId: OrganizationId) {
  return api.get<ApplicationSuite[]>(`/organizations/${organizationId}/application-suites`);
}

/**
 * Gets a single specific application suite.
 * @param organizationId The organization ID.
 * @param applicationSuiteId The ID of the application suite to get.
 * @returns A `Promise` that emits the application suite when the request has completed.
 */
export function getApplicationSuite(
  organizationId: OrganizationId,
  applicationSuiteId: ApplicationSuiteId
) {
  return api.get<ApplicationSuite>(
    `/organizations/${organizationId}/application-suites/${applicationSuiteId}`
  );
}

/**
 * Creates a new application suite for the specified organization.
 * @param organizationId The organization ID.
 * @param payload The request payload used to create the application suite.
 * @returns A `Promise` that emits the created application suite when the request has completed.
 */
export function createApplicationSuite(
  organizationId: OrganizationId,
  payload: CreateApplicationSuitePayload
) {
  return api.post<ApplicationSuite>(`/organizations/${organizationId}/application-suites`, payload);
}

/**
 * Updates an existing application suite.
 * @param organizationId The organization ID.
 * @param applicationSuiteId The ID of the application suite to update.
 * @param payload The request payload used to update the application suite.
 * @returns A `Promise` that emits the updated application suite when the request has completed.
 */
export function updateApplicationSuite(
  organizationId: OrganizationId,
  applicationSuiteId: ApplicationSuiteId,
  payload: UpdateApplicationSuitePayload
) {
  return api.put<ApplicationSuite>(
    `/organizations/${organizationId}/application-suites/${applicationSuiteId}`,
    payload
  );
}

/**
 * Deletes an existing application suite.
 * @param organizationId The organization ID.
 * @param applicationSuiteId The ID of the application suite to delete.
 * @returns A `Promise` that emits when the application suite deletion request has completed.
 */
export function deleteApplicationSuite(
  organizationId: OrganizationId,
  applicationSuiteId: ApplicationSuiteId
) {
  return api.delete(`/organizations/${organizationId}/application-suites/${applicationSuiteId}`);
}
