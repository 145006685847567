/**
 * A strongly-typed organization role ID.
 */
export enum OrganizationRoleId {
  /**
   * The ID represents an organization Admin role.
   */
  Admin = 1,

  /**
   * The ID represents an organization Maintainer role.
   */
  Maintainer = 2,

  /**
   * The ID represents an organization User role.
   */
  User = 3,
}

/**
 * Represents the organization role entity.
 */
export interface OrganizationRole {
  /**
   * Gets the role ID.
   */
  readonly id: OrganizationRoleId;

  /**
   * Gets the role name.
   */
  readonly name: string;

  /**
   * Gets the role description.
   */
  readonly description: string | null;
}
