/**
 * A strongly-typed category ID.
 */
export type CategoryId = number;

/**
 * Represents the category entity.
 */
export interface Category {
  /**
   * Gets the category ID.
   */
  readonly id: CategoryId;

  /**
   * Gets the category name.
   */
  readonly name: string;

  /**
   * Gets the category type.
   */
  readonly type: CategoryType;

  /**
   * Gets the category sort order.
   */
  sortOrder: number;

  /**
   * Gets the parent category ID.
   */
  readonly parentCategoryId: number | null;

  /**
   * Gets the root category ID.
   */
  readonly rootCategoryId: number | null;

  /**
   * Gets the date the category was created.
   */
  readonly created: string;

  /**
   * Gets the date the category was last updated.
   */
  readonly updated: string;
}

/**
 * Represents the allowed category type values.
 */
export enum CategoryType {
  DataViews = 0,

  Reports = 1,

  MimicBoards = 2,

  Maps = 3,
}

/**
 * Request payload used to create a new category.
 */
export interface CreateCategoryPayload {
  /**
   * The category name.
   */
  name: string;

  /**
   * The category type.
   */
  type?: CategoryType;

  /**
   * An optional parent category ID. The new category will be a child of the specified parent.
   */
  parentCategoryId?: CategoryId | null;
}

/**
 * The request payload used to update an existing category.
 */
export interface UpdateCategoryPayload {
  /**
   * The category name.
   */
  name: string;

  /**
   * An optional new type for the category. Changes to the type will cascade down to all child categories.
   */
  type?: CategoryType;

  /**
   * An optional parent category ID. Changes to the parent category ID might change the root category of existing child categories.
   */
  parentCategoryId?: CategoryId;
}
