import { ReactNode, forwardRef } from 'react';
import { FiCheck } from 'react-icons/fi';

import { FlexItem } from 'components/flex';
import { Text } from 'components/text';
import { styled } from 'stitches';

const OptionRoot = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$3',

  lineHeight: '$snug',
  outline: 'none',
  cursor: 'pointer',
  color: '$neutral-700',

  padding: '$3 $4',

  '&:focus, &:hover': {
    backgroundColor: '$neutral-50',
  },

  '&:active': {
    backgroundColor: '$neutral-100',
  },

  '&[aria-disabled=true]': {
    pointerEvents: 'none',
    color: '$neutral-300',
  },
});

export type OptionProps = {
  value?: any;

  isSelected?: boolean;

  children?: ReactNode;
};

export const Option = forwardRef<HTMLDivElement, OptionProps>(function Option(props, forwardedRef) {
  const { isSelected = false, children, ...rest } = props;

  return (
    <OptionRoot tabIndex={-1} ref={forwardedRef} role="option" {...rest}>
      <FlexItem shrink={0} css={{ flexBasis: '$5' }}>
        {isSelected ? <FiCheck /> : null}
      </FlexItem>

      <Text as="div" truncate>
        {children}
      </Text>
    </OptionRoot>
  );
});
