/**
 * A strongly-typed data validation control ID.
 */
export type DataValidationControlId = number;

export enum CheckSeverity {
  Warning = 'Warning',
  Error = 'Error',
}

/**
 * Represents the data validation control entity.
 */
export interface DataValidationControl {
  /**
   * Gets the data validation control ID.
   */
  readonly id: number;

  /**
   * Gets the data validation control validation type ID.
   */
  readonly dataValidationTypeId: number;

  /**
   * Gets the data validation control validation type ID.
   */
  readonly dataValidationTableId: number;

  /**
   * Gets the data validation control data view ID.
   */
  readonly dataViewId: number;

  /**
   * Gets the data validation control active.
   */
  readonly active: boolean;

  /**
   * Gets the data validation control check expression.
   */
  readonly checkExpression: string;

  /**
   * Gets the data validation control check severity.
   */
  readonly checkSeverity: CheckSeverity;

  /**
   * Gets the data validation control description.
   */
  readonly description: string;

  /**
   * Gets the data validation control reference table.
   */
  readonly referenceTable: string;

  /**
   * Gets the data validation control join expression.
   */
  readonly joinExpression: string;

  /**
   * Gets the date the import definition fields.
   */
  readonly dataValidationFields: DataValidationField[];
}

export interface DataValidationControlEntity extends DataValidationControl {
  /**
   * Gets the data validation control table name.
   */
  readonly tableName?: string;

  /**
   * Gets the data validation control validation type.
   */
  readonly validationType?: string;
}

/**
 * A strongly-typed data validation control detail ID.
 */
export type DataValidationControlDetailId = number;

/**
 * Represents the data validation control detail entity.
 */
export interface DataValidationControlDetail {
  /**
   * Gets the data validation control ID.
   */
  readonly id: number;

  /**
   * Gets the data validation control validation type ID.
   */
  readonly dataValidationTypeId: number;

  /**
   * Gets the data validation control validation type ID.
   */
  readonly dataValidationTableId: number;

  /**
   * Gets the data validation control active.
   */
  readonly active: boolean;

  /**
   * Gets the data validation control check expression.
   */
  readonly checkExpression: string;

  /**
   * Gets the data validation control check severity.
   */
  readonly checkSeverity: CheckSeverity;

  /**
   * Gets the data validation control description.
   */
  readonly description: string;

  /**
   * Gets the data validation control reference table.
   */
  readonly referenceTable: string;

  /**
   * Gets the data validation control join expression.
   */
  readonly joinExpression: string;

  /**
   * Gets the date the import definition fields.
   */
  readonly dataValidationFields: DataValidationField[];
}

/**
 * Request payload used to create a new data validation control.
 */
export interface CreateDataValidationControlPayload {
  dataValidationTypeId: number;
  dataValidationTableId: number;
  active: boolean;
  checkExpression: string;
  checkSeverity: CheckSeverity;
  description: string;
  referenceTable: string;
  joinExpression: string;
  dataValidationFields: DataValidationField[];
}

/**
 * Request payload used to update an existing data validation control.
 */
export type UpdateDataValidationControlPayload = CreateDataValidationControlPayload;

export interface DataValidationField {
  /**
   * Gets the data validation ID.
   */
  readonly id: number;

  /**
   * Gets the data validation control ID.
   */
  readonly dataValidationControlId: number;

  /**
   * Gets the data validation active.
   */
  readonly active: boolean;

  /**
   * Gets the data validation columnA.
   */
  readonly columnA: string;

  /**
   * Gets the data validation columnB.
   */
  readonly columnB: string;

  /**
   * Gets the data validation column reference table.
   */
  readonly columnReferenceTable: string;

  /**
   * Gets the data validation column join expression.
   */
  readonly columnJoinExpression: string;
}

export interface DataValidationType {
  /**
   * Gets the data validation type ID.
   */
  readonly id: number;

  /**
   * Gets the active.
   */
  readonly active: boolean;

  /**
   * Gets the check type.
   */
  readonly checkType: string;

  /**
   * Gets the message.
   */
  readonly message: string;

  /**
   * Gets the validation query.
   */
  readonly validationQuery: string;

  /**
   * Gets the isSingleRowValidation.
   */
  readonly isSingleRowValidation: boolean;
}
