import { useCallback } from 'react';

import { useOpenState, useSelectionState } from 'hooks';

export type UseSelectOptions<T = unknown> = {
  /**
   * The current value.
   */
  value?: T;

  /**
   * The select ID.
   */
  id?: string;

  /**
   * Whether or not multiple values are selectable. Defaults to `false`.
   */
  multiple?: boolean;
};

export function useSelect<T = unknown>(opts: UseSelectOptions<T> = {}) {
  const { value, multiple = false } = opts;

  const { isOpen, close, open, toggle } = useOpenState();

  const selection = useSelectionState({
    initialValue: value,
    multiple,
  });

  const selectValue = useCallback(
    (newValue: T) => {
      let nextValue: Set<T>;
      if (multiple) {
        nextValue = selection.toggle(newValue);
      } else {
        nextValue = selection.select(newValue);
      }

      return Array.from(nextValue);
    },
    [multiple, selection]
  );

  return {
    values: selection.values,
    isOpen,
    close,
    open,
    toggle,
    selectValue,
  };
}
