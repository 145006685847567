import { ComponentProps, forwardRef } from 'react';

import { styled } from 'stitches';
import { gutters } from 'stitches/helpers';

const AlertRoot = styled('div', gutters, {
  padding: '$4',
  borderRadius: '$default',

  variants: {
    colorScheme: {
      neutral: {
        backgroundColor: '$neutral-100',
      },
      primary: {
        backgroundColor: '$primary-100',
        color: '$primary-900',
      },
      warning: {
        backgroundColor: '$warning-100',
        color: '$warning-900',
      },
      danger: {
        backgroundColor: '$danger-100',
        color: '$danger-900',
      },
      success: {
        backgroundColor: '$success-200',
        color: '$success-900',
      },
    },
  },

  defaultVariants: {
    colorScheme: 'neutral',
  },
});

export type AlertProps = ComponentProps<typeof AlertRoot>;

export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, forwardedRef) {
  return (
    <AlertRoot
      ref={forwardedRef}
      role="alert"
      // TODO: Remove any cast
      {...(props as any)}
    />
  );
});
