import { ReactNode } from 'react';

import { Button } from 'components/button';
import { Card } from 'components/card';
import { Flex } from 'components/flex';
import { List } from 'components/list';
import { Text } from 'components/text';

import { useEntityListContext } from './EntityListContext';

export interface EntityListProps<T> {
  children: (item: T, index: number) => ReactNode;
}

export function EntityList<T>(props: EntityListProps<T>) {
  const { children: renderFn } = props;
  const { items, isFiltered, resetQuery } = useEntityListContext();

  if (!items.length) {
    return (
      <Flex justify="center" css={{ padding: '$10' }}>
        <Text variant="secondary">
          No items to display.{' '}
          {isFiltered ? (
            <Button variant="link" onClick={resetQuery}>
              Clear current query
            </Button>
          ) : null}
        </Text>
      </Flex>
    );
  }

  return (
    <Card css={{ boxShadow: '$sm' }}>
      <List>{items.map((item, index) => renderFn(item, index))}</List>
    </Card>
  );
}
