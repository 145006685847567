import { AppSettings, AppVersion } from 'interfaces';

import { api } from './api';

/**
 * Gets the global app settings.
 * @returns A `Promise` for getting the settings.
 */
export function getAppSettings() {
  return api.get<AppSettings>(`settings`);
}

/**
 * Gets the app version information.
 * @returns A `Promise` containing the app version.
 */
export function getAppVersion() {
  return api.get<AppVersion>(`settings/version`);
}
