import { Suspense, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useCurrentOrganization } from 'app';
import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { FieldLabel, FormField } from 'components/form-field';
import { LoadingSpinner } from 'components/loading-spinner';
import { Option, Select } from 'components/select';
import { Text } from 'components/text';

import { useOrganizationThemeQuery, useOrganizationsQuery } from 'data/organizations';

import { Organization } from 'interfaces';

import { styled } from 'stitches';

import { AppSuites } from './AppSuites';

export type QuickNavigationProps = {
  isOpen?: boolean;
  onClose?(): void;
};

type QuickNavigationFormValue = {
  selectedOrganizationId?: number;
};

export function QuickNavigation(props: QuickNavigationProps) {
  const { isOpen, onClose } = props;

  const currentOrganization = useCurrentOrganization(false);
  const organizations = useOrganizationsQuery().organizations.sort((org1, org2) =>
    org1.name.localeCompare(org2.name)
  );

  const handleDrawerClose = () => {
    onClose?.();
  };

  const { control, watch, setValue } = useForm<QuickNavigationFormValue>({
    mode: 'onChange',
    defaultValues: {
      selectedOrganizationId: undefined,
    },
  });

  const selectedOrganizationId = watch('selectedOrganizationId');
  const selectedOrganization =
    organizations.length === 1
      ? organizations[0]
      : organizations.find((org) => org.id === selectedOrganizationId);

  useEffect(() => {
    if (
      !selectedOrganization ||
      (currentOrganization && currentOrganization?.id !== selectedOrganization?.id && !isOpen)
    ) {
      setValue('selectedOrganizationId', currentOrganization?.id);
    }
  }, [currentOrganization, selectedOrganization, isOpen, setValue]);

  return (
    <Drawer open={isOpen} onClose={handleDrawerClose}>
      <Suspense fallback={<LoadingSpinner />}>
        <DrawerHeader>
          {selectedOrganization && (
            <SelectedOrganisationTitle organization={selectedOrganization} />
          )}
          {organizations.length > 1 && (
            <FormField>
              <FieldLabel>Organization</FieldLabel>
              <Controller
                name="selectedOrganizationId"
                control={control}
                render={({ field }) => (
                  <Select placeholder={'Choose an organization'} {...field}>
                    {organizations.map((org) => (
                      <Option key={org.id} value={org.id}>
                        {org.name}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </FormField>
          )}
        </DrawerHeader>

        <DrawerContent css={{ overflowX: 'hidden' }}>
          {selectedOrganization && <AppSuites organizationId={selectedOrganization.id} />}
        </DrawerContent>
      </Suspense>
    </Drawer>
  );
}

function SelectedOrganisationTitle(props: { organization: Organization }) {
  const { organization } = props;
  const { organizationTheme } = useOrganizationThemeQuery(organization.id);

  const StyledOrganisationTitle = styled(Text, {
    color: organizationTheme.primaryColor,
    marginBottom: '$6',
  });

  return (
    <StyledOrganisationTitle as="div" variant="title2" title={organization.name} truncate>
      {organization.name}
    </StyledOrganisationTitle>
  );
}
