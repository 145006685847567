import { Box } from 'components/box';
import { Divider } from 'components/divider';
import {
  EntityList,
  EntityListContextProvider,
  EntityListPagination,
  EntityListSearch,
  EntityListSort,
  useEntityList,
} from 'components/entity-list';
import { Flex } from 'components/flex';
import { Link } from 'components/link';
import { ListItem, ListItemContent, ListItemMeta } from 'components/list';
import { Text } from 'components/text';
import { Application } from 'interfaces';

export type ApplicationListProps = {
  applications: Application[];
};

export function ApplicationList(props: ApplicationListProps) {
  const { applications } = props;

  const entityList = useEntityList({
    source: applications,
    searchKeys: ['name'],
    sorting: [
      {
        key: 'name',
        label: 'Name',
      },
    ],
  });

  return (
    <EntityListContextProvider value={entityList}>
      <Flex gutterBottom="lg">
        <Box css={{ width: '45%' }}>
          <EntityListSearch />
        </Box>
      </Flex>

      <Flex gutterBottom="lg">
        <EntityListSort />
      </Flex>

      <EntityList>
        {(item: Application) => (
          <ListItem key={item.id}>
            <ListItemContent>
              <Text as={Link} size="lg" to={`/${item.fullPath}`}>
                {item.name}
              </Text>

              <ListItemMeta>{item.organization.name}</ListItemMeta>
            </ListItemContent>

            <Text size="sm">
              <Flex gap="3" css={{ px: '$3' }}>
                <Link to={`/${item.fullPath}/dashboard`}>Dashboard</Link>
                <Divider flexItem orientation="vertical" />
                <Link to={`/${item.fullPath}/scenarios`}>Scenarios</Link>
                <Divider flexItem orientation="vertical" />
                <Link to={`/${item.fullPath}/reports`}>Reports</Link>
                <Divider flexItem orientation="vertical" />
                <Link to={`/${item.fullPath}/compare-jobs`}>Compare jobs</Link>
                <Divider flexItem orientation="vertical" />
                <Link to={`/${item.fullPath}/mimic-boards`}>Mimic boards</Link>
              </Flex>
            </Text>
          </ListItem>
        )}
      </EntityList>

      <EntityListPagination />
    </EntityListContextProvider>
  );
}
