import { useSearchParams } from 'react-router-dom';

import { ApplicationId } from 'interfaces';
import { Tour } from 'interfaces/tours';
import { createAtomFamily } from 'utils';

export type ApplicationState = {
  toursTriggered: boolean;
  tours: Tour[];
};

export type ApplicationStateStateParam = {
  applicationId: ApplicationId;
};

const { atom: editorViewAtom, createKeyedSelectorFamily } = createAtomFamily<
  ApplicationState,
  ApplicationStateStateParam
>({
  key: 'application',
  defaultValue: {
    toursTriggered: false,
    tours: [],
  },
});

export function useHeaderlessMode() {
  const paramName = 'headerless';
  const [searchParams] = useSearchParams();

  return searchParams.get(paramName) === '1' || searchParams.get(paramName) === 'true';
}

export const applicationToursTriggered = createKeyedSelectorFamily('toursTriggered');
export const applicationTours = createKeyedSelectorFamily('tours');

export { editorViewAtom };
