import { ReactNode } from 'react';

import {
  AccordionContextProvider,
  AccordionDescendantManagerContext,
  UseAccordionProviderOpts,
  useAccordionContextProvider,
  useAccordionDescendantManager,
} from './AccordionContext';

export type AccordionProps = UseAccordionProviderOpts & {
  /**
   * The accordion content.
   */
  children?: ReactNode;
};

/**
 * Root component for rendering accordion panels.
 * @param props The accordion props.
 */
export function Accordion(props: AccordionProps) {
  const { children } = props;

  const descendantManager = useAccordionDescendantManager();
  const context = useAccordionContextProvider(props);

  return (
    <AccordionDescendantManagerContext value={descendantManager}>
      <AccordionContextProvider value={context}>{children}</AccordionContextProvider>
    </AccordionDescendantManagerContext>
  );
}
