import { ReactNode } from 'react';

import { Box } from 'components/box';
import { Collapse } from 'components/transitions';

import { useAccordionPanelContext } from './AccordionContext';

export type AccordionPanelContentProps = {
  children?: ReactNode;
};

export function AccordionPanelContent(props: AccordionPanelContentProps) {
  const { children } = props;

  const { isOpen, contentId, headerId } = useAccordionPanelContext();

  return (
    <Collapse in={isOpen}>
      <Box id={contentId} role="region" aria-labelledby={headerId} css={{ padding: '$3' }}>
        {children}
      </Box>
    </Collapse>
  );
}
