import {
  ApplicationId,
  CreateDataImportGroupPayload,
  CreateDataImportUploadPayLoad,
  DataImportGroup,
  DataImportGroupId,
  DataImportSession,
  DataImportSessionId,
  UpdateDataImportGroupPayload,
  ValidateDataImportUploadPayLoad,
} from 'interfaces';

import { api, toFormData } from './api';

/**
 * Gets the data import groups for the specified application.
 * @param applicationId The application ID.
 * @returns A `Promise` containing the import groups.
 */
export function getDataImportGroups(applicationId: ApplicationId) {
  return api.get<DataImportGroup[]>(`/applications/${applicationId}/data-import-groups`);
}

/**
 * Fetches a single data import group belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataImportGroupId The data import group ID.
 */
export function getDataImportGroup(
  applicationId: ApplicationId,
  dataImportGroupId: DataImportGroupId
) {
  return api.get<DataImportGroup>(
    `/applications/${applicationId}/data-import-groups/${dataImportGroupId}`
  );
}

/**
 * Creates a new data import group for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the data import group.
 */
export function createDataImportGroup(
  applicationId: ApplicationId,
  payload: CreateDataImportGroupPayload
) {
  return api.post<DataImportGroup>(`/applications/${applicationId}/data-import-groups`, payload);
}

/**
 * Updates an existing data import group.
 * @param applicationId The application ID.
 * @param dataImportGroupId The data import group ID.
 * @param payload The request payload.
 * @returns The updated data import group.
 */
export function updateDataImportGroup(
  applicationId: ApplicationId,
  dataImportGroupId: DataImportGroupId,
  payload: UpdateDataImportGroupPayload
) {
  return api.put<DataImportGroup>(
    `/applications/${applicationId}/data-import-groups/${dataImportGroupId}`,
    payload
  );
}

/**
 * Deletes an existing data import group belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataImportGroupId The data import group ID.
 */
export function deleteDataImportGroup(
  applicationId: ApplicationId,
  dataImportGroupId: DataImportGroupId
) {
  return api.delete(`/applications/${applicationId}/data-import-groups/${dataImportGroupId}`);
}

export async function getTemplateFile(
  applicationId: ApplicationId,
  dataImportGroupId: DataImportGroupId
): Promise<{ blob: Blob; filename?: string }> {
  const res = await api.request<Blob>(
    `/applications/${applicationId}/data-import-groups/${dataImportGroupId}/download`,
    'GET',
    {
      responseType: 'blob',
    }
  );
  const contentDisposition = String(res.headers['content-disposition'])
    ?.split(';')
    .map((parts) => parts.trim().split('='))
    .reduce((obj: { [key: string]: string | null }, [key, value]) => {
      return { ...obj, [key]: value ? value.split('"').join('') : null };
    }, {});

  return { blob: res.data, filename: contentDisposition?.filename || undefined };
}

/**
 * Validates Data Import Group file.
 * @param applicationId The application ID.
 * @param payload The request payload used to upload data import group.
 */
export function ValidateDataImportGroupFile(
  applicationId: ApplicationId,
  payload: ValidateDataImportUploadPayLoad
) {
  return api.post<boolean>(
    `/applications/${applicationId}/data-import-groups/validate`,
    toFormData(payload)
  );
}

/**
 * Upload Data Import Group file.
 * @param applicationId The application ID.
 * @param payload The request payload used to upload data import group.
 */
export function UploadDataImportGroupFile(
  applicationId: ApplicationId,
  payload: CreateDataImportUploadPayLoad
) {
  return api.post(
    `/applications/${applicationId}/data-import-groups/import`,
    toFormData(payload, true)
  );
}

/**
 * Export Data Import Group imported data file with errors.
 * @param applicationId The application ID.
 * @param dataImportSessionId The data import session ID.
 */
export async function DownloadDataImportedFile(
  applicationId: ApplicationId,
  dataImportSessionId: DataImportSessionId
): Promise<{ blob: Blob; filename?: string }> {
  const res = await api.request<Blob>(
    `/applications/${applicationId}/data-import-groups/${dataImportSessionId}/downloadDataImportedField`,
    'GET',
    {
      responseType: 'blob',
    }
  );
  const contentDisposition = String(res.headers['content-disposition'])
    ?.split(';')
    .map((parts) => parts.trim().split('='))
    .reduce((obj: { [key: string]: string | null }, [key, value]) => {
      return { ...obj, [key]: value ? value.split('"').join('') : null };
    }, {});

  return { blob: res.data, filename: contentDisposition?.filename || undefined };
}

/**
 * Fetches a single data import session belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataImportSessionId The data import group ID.
 */
export function getDataImportSession(
  applicationId: ApplicationId,
  dataImportSessionId: DataImportSessionId
) {
  return api.get<DataImportSession>(
    `/applications/${applicationId}/data-import-groups/${dataImportSessionId}/dataImportSession`
  );
}

/**
 * Roll back imported data.
 * @param applicationId The application ID.
 * @param dataImportSessionId The data import session ID.
 */
export function rollbackDataImport(
  applicationId: ApplicationId,
  dataImportSessionId: DataImportSessionId
) {
  return api.post(
    `/applications/${applicationId}/data-import-groups/${dataImportSessionId}/rollbackDataImport`,
    {}
  );
}
