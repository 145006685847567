import { ReactNode, forwardRef } from 'react';

import { styled } from 'stitches';
import type * as Polymorphic from 'stitches/polymorphic';

import { useFormFieldContext } from './FormFieldContext';

const RequiredFieldIndicator = styled('span', {
  color: '$danger-500',
  marginInlineStart: '$1',
  display: 'inline-block',
});

export const DEFAULT_LABEL_TAG = 'label';

const StyledFieldLabel = styled(DEFAULT_LABEL_TAG, {
  display: 'block',
  marginBottom: '$2',
  marginInlineStart: '1px',

  fontWeight: '$medium',
  textAlign: 'left',
});

export type FieldLabelProps = {
  children?: ReactNode;
};

export const FieldLabel = forwardRef(function FieldLabel(props, forwardedRef) {
  const { as, children, style, ...rest } = props;

  const { required, id } = useFormFieldContext();

  return (
    <StyledFieldLabel
      as={as}
      ref={forwardedRef}
      htmlFor={id}
      // TODO: Remove any cast
      css={style as any}
      {...rest}
    >
      {children}
      {required && (
        <RequiredFieldIndicator role="presentation" aria-hidden="true">
          *
        </RequiredFieldIndicator>
      )}
    </StyledFieldLabel>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_LABEL_TAG, FieldLabelProps>;
