import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as applicationSuites from 'api/application-suites';

import { OrganizationId } from 'interfaces';
import {
  ApplicationSuite,
  ApplicationSuiteId,
  CreateApplicationSuitePayload,
  UpdateApplicationSuitePayload,
} from 'interfaces/application-suite';

const appSuiteQueryKeys = {
  all: (organizationId: number) => ['application-suites', organizationId] as const,
  specific: (organizationId: number, applicationSuiteId: number) =>
    [...appSuiteQueryKeys.all(organizationId), applicationSuiteId] as const,
};

/**
 * Hook used to query all application suites for a specific organisation.
 * @param organizationId The organization ID.
 * @returns The `ApplicationSuite` items for the given organisation.
 */
export function useApplicationSuitesQuery(organizationId: OrganizationId) {
  const { data } = useQuery(appSuiteQueryKeys.all(organizationId), () =>
    applicationSuites.getApplicationSuites(organizationId)
  );

  return {
    applicationSuites: data,
  };
}

/**
 * Hook used to query an application suite for a specific organisation.
 * @param organizationId The organization ID.
 * @param applicationSuiteId The application suite ID.
 * @returns The `ApplicationSuite`.
 */
export function useApplicationSuiteQuery(
  organizationId: OrganizationId,
  applicationSuiteId: ApplicationSuiteId
) {
  const queryClient = useQueryClient();

  const { data } = useQuery(
    appSuiteQueryKeys.specific(organizationId, applicationSuiteId),
    () => applicationSuites.getApplicationSuite(organizationId, applicationSuiteId),
    {
      initialData: () =>
        queryClient
          .getQueryData<ApplicationSuite[]>(appSuiteQueryKeys.all(organizationId))
          ?.find((appSuite) => appSuite.id === applicationSuiteId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(appSuiteQueryKeys.all(organizationId))?.dataUpdatedAt,
    }
  );

  return {
    applicationSuite: data,
  };
}

/**
 * Hook that returns a mutation used to create a new application suite.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useCreateApplicationSuiteMutation(organizationId: OrganizationId) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateApplicationSuitePayload) =>
      applicationSuites.createApplicationSuite(organizationId, payload),
    {
      onSuccess: (newApplicationSuiteData) => {
        queryClient.setQueryData<ApplicationSuite[]>(
          appSuiteQueryKeys.all(organizationId),
          (applicationSuiteList) => [...(applicationSuiteList || []), newApplicationSuiteData]
        );

        queryClient.invalidateQueries(appSuiteQueryKeys.all(organizationId));
      },
    }
  );
}

export type UseUpdateApplicationSuiteMutationPayload = UpdateApplicationSuitePayload & {
  applicationSuiteId: ApplicationSuiteId;
};

/**
 * Hook that returns a mutation used to update an existing application suite.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useUpdateApplicationSuiteMutation(organizationId: OrganizationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ applicationSuiteId, ...payload }: UseUpdateApplicationSuiteMutationPayload) =>
      applicationSuites.updateApplicationSuite(organizationId, applicationSuiteId, payload),
    {
      onSuccess: (updatedApplicationSuiteData) => {
        queryClient.setQueryData<ApplicationSuite>(
          appSuiteQueryKeys.specific(organizationId, updatedApplicationSuiteData.id),
          updatedApplicationSuiteData
        );
        queryClient.setQueryData<ApplicationSuite[]>(
          appSuiteQueryKeys.all(organizationId),
          (applicationSuiteList) => {
            return (
              applicationSuiteList?.map((suite) =>
                suite.id === updatedApplicationSuiteData.id ? updatedApplicationSuiteData : suite
              ) || [updatedApplicationSuiteData]
            );
          }
        );

        queryClient.invalidateQueries(appSuiteQueryKeys.all(organizationId));
      },
    }
  );
}

export type UseDeleteApplicationSuiteMutationPayload = {
  applicationSuiteId: ApplicationSuiteId;
};

/**
 * Hook that returns a mutation used to delete an existing application suite.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useDeleteApplicationSuiteMutation(organizationId: OrganizationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ applicationSuiteId }: UseDeleteApplicationSuiteMutationPayload) =>
      applicationSuites.deleteApplicationSuite(organizationId, applicationSuiteId),
    {
      onSuccess: (_, { applicationSuiteId }) => {
        queryClient.setQueryData<ApplicationSuite[]>(
          appSuiteQueryKeys.all(organizationId),
          (applicationSuiteList) =>
            applicationSuiteList?.filter((suite) => suite.id !== applicationSuiteId) ?? []
        );

        queryClient.invalidateQueries({
          queryKey: appSuiteQueryKeys.all(organizationId),
          refetchActive: false,
          refetchInactive: false,
        });
      },
    }
  );
}
