import { ReactNode, forwardRef } from 'react';

import { CSS, styled } from 'stitches';
import type * as Polymorphic from 'stitches/polymorphic';

import { FormFieldContextProvider, useFormFieldContextProvider } from './FormFieldContext';

const DEFAULT_TAG = 'div';

const StyledFormField = styled(DEFAULT_TAG, {
  display: 'block',
  marginBottom: '$5',
});

export type FormFieldProps = {
  /**
   * Whether the field is required.
   */
  required?: boolean;

  /**
   * Whether the field is disabled.
   */
  disabled?: boolean;

  /**
   * The form field content.
   */
  children?: ReactNode;

  /**
   * Custom styling.
   */
  css?: CSS;

  /**
   * The form field ID.
   */
  id?: string;

  /**
   * The form field error(s).
   */
  error?: string | string[] | null | boolean;
};

/**
 * Component used for displaying form fields.
 */
export const FormField = forwardRef((props, forwardedRef) => {
  const { as, children, css, id, error, required, disabled, ...rest } = props;

  const context = useFormFieldContextProvider({
    id,
    error,
    required,
  });

  const fieldDisabledCss = {
    backgroundColor: '$neutral-50',
    borderColor: '$neutral-200',
    pointerEvents: 'none',
    color: '$neutral-400',
  };

  const newCss = disabled ? { ...fieldDisabledCss, ...css } : css;

  return (
    <FormFieldContextProvider value={context}>
      {/** TODO: remove any cast */}
      <StyledFormField as={as} ref={forwardedRef} role="group" css={newCss} {...rest}>
        {children}
      </StyledFormField>
    </FormFieldContextProvider>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, FormFieldProps>;
