import { DataViewMode } from 'features/application/editor/types';
import {
  ApplicationId,
  CreateDataViewPayload,
  CreateDataViewPersonalGraphPayload,
  CreateDataViewPersonalKpiPayload,
  DataView,
  DataViewGraphId,
  DataViewId,
  DataViewKpiId,
  DataViewStpDetail,
  DataViewValidationResult,
  ScenarioId,
  UpdateDataViewPayload,
  UpdateDataViewPersonalGraphPayload,
  UpdateDataViewPersonalKpiPayload,
  ValidateDataViewsPayload,
  ValidateSQLViewResult,
  ValueOption,
} from 'interfaces';

import { api } from './api';

/**
 * Fetches all data views belonging to the specified application.
 * @param applicationId the application ID.
 */
export function getDataViews(applicationId: ApplicationId) {
  return api.get<DataView[]>(`/applications/${applicationId}/data-views`);
}

/**
 * Creates a new data view for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the data view.
 */
export function createDataView(applicationId: ApplicationId, payload: CreateDataViewPayload) {
  return api.post<DataView>(`/applications/${applicationId}/data-views`, payload);
}

/**
 * Creates a new personal graph for the specified data view.
 * @param applicationId The application ID.
 * @param dataViewId the data view ID.
 * @param payload The request payload used to create the data view graph.
 */
export function createDataViewPersonalGraph(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  payload: CreateDataViewPersonalGraphPayload
) {
  return api.post<DataView>(
    `/applications/${applicationId}/data-views/${dataViewId}/personal-graph`,
    payload
  );
}

/**
 * Creates a new personal kpi for the specified data view.
 * @param applicationId The application ID.
 * @param dataViewId the data view ID.
 * @param payload The request payload used to create the data view kpi.
 */
export function createDataViewPersonalKpi(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  payload: CreateDataViewPersonalKpiPayload
) {
  return api.post<DataView>(
    `/applications/${applicationId}/data-views/${dataViewId}/personal-kpi`,
    payload
  );
}

/**
 * Deletes an existing data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 */
export function deleteDataView(applicationId: ApplicationId, dataViewId: DataViewId) {
  return api.delete(`/applications/${applicationId}/data-views/${dataViewId}`);
}

/**
 * Deletes an existing personal graph belonging to the specified data view.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 * @param dataViewGraphId The data view personal graph ID.
 */
export function deleteDataViewPersonalGraph(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  dataViewGraphId: DataViewGraphId
) {
  return api.delete<DataView>(
    `/applications/${applicationId}/data-views/${dataViewId}/personal-graph/${dataViewGraphId}`
  );
}

/**
 * Deletes an existing personal kpi belonging to the specified data view.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 * @param dataViewKpiId The data view personal kpi ID.
 */
export function deleteDataViewPersonalKpi(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  dataViewKpiId: DataViewKpiId
) {
  return api.delete<DataView>(
    `/applications/${applicationId}/data-views/${dataViewId}/personal-kpi/${dataViewKpiId}`
  );
}

/**
 * Updates an existing data view's sort order belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 * @param currentSort The data view current sort order.
 * @param newSort The data view updated sort order.
 */
export function setDataViewSortOrder(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  currentSort: number,
  newSort: number
) {
  return api.patch<void>(`/applications/${applicationId}/data-views/${dataViewId}/sort`, {
    currentSort,
    newSort,
  });
}

/**
 * Fetches a single data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 */
export function getDataView(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  dataViewMode?: DataViewMode,
  isDataViewForm?: boolean
) {
  return api.get<DataView>(`/applications/${applicationId}/data-views/${dataViewId}`, {
    params: {
      dataViewMode,
      isDataViewForm,
    },
  });
}

/**
 * Fetches the STP details for a data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 * @param scenarioId The scenario ID.
 */
export function getDataViewStpDetails(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  scenarioId?: ScenarioId | null
) {
  const params: { [key: string]: string } = {};
  if (scenarioId) {
    params.scenarioId = scenarioId.toString();
  }

  return api.get<DataViewStpDetail[]>(
    `/applications/${applicationId}/data-views/${dataViewId}/stp-details`,
    {
      params,
    }
  );
}

/**
 * Updates an existing data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId the data view ID.
 * @param payload The request payload used to update the data view.
 */
export function updateDataView(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  payload: UpdateDataViewPayload
) {
  return api.put<DataView>(`/applications/${applicationId}/data-views/${dataViewId}`, payload);
}

/**
 * Updates an existing personal graph belonging to the specified data view .
 * @param applicationId The application ID.
 * @param dataViewId the data view ID.
 * @param payload The request payload used to update the graph.
 */
export function updateDataViewPersonalGraph(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  payload: UpdateDataViewPersonalGraphPayload
) {
  return api.put<DataView>(
    `/applications/${applicationId}/data-views/${dataViewId}/personal-graph`,
    payload
  );
}

/**
 * Updates an existing personal kpi belonging to the specified data view .
 * @param applicationId The application ID.
 * @param dataViewId the data view ID.
 * @param payload The request payload used to update the kpi.
 */
export function updateDataViewPersonalKpi(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  payload: UpdateDataViewPersonalKpiPayload
) {
  return api.put<DataView>(
    `/applications/${applicationId}/data-views/${dataViewId}/personal-kpi`,
    payload
  );
}

/**
 * Adds or removes the data view as a favorite for the current user.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 * @param isFavorite
 */
export function setDataViewFavorited(
  applicationId: ApplicationId,
  dataViewId: ScenarioId,
  isFavorite: boolean
) {
  return api.patch<void>(`/applications/${applicationId}/data-views/${dataViewId}/favorite`, {
    isFavorite,
  });
}

/**
 * Fetches the value options for a data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 * @param scenarioId The scenario ID.
 */
export function getDataViewValueOptions(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  scenarioId: ScenarioId,
  dataViewMode?: DataViewMode
) {
  const params: { [key: string]: string } = {};
  params.scenarioId = scenarioId.toString();
  params.dataViewMode =
    dataViewMode !== undefined ? String(dataViewMode) : String(DataViewMode.DefaultMode);

  return api.get<ValueOption[]>(
    `/applications/${applicationId}/data-views/${dataViewId}/value-options`,
    {
      params,
    }
  );
}

/**
 * Adds or removes the data view as a favorite for the current user.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 */
export function validateSQLDataView(applicationId: ApplicationId, dataViewId: ScenarioId) {
  return api.get<ValidateSQLViewResult>(
    `/applications/${applicationId}/data-views/${dataViewId}/validate`
  );
}

/**
 * Validate the data views for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the data view.
 */
export function validateDataViews(applicationId: ApplicationId, payload: ValidateDataViewsPayload) {
  return api.post<DataViewValidationResult[]>(
    `/applications/${applicationId}/data-views/validate`,
    payload
  );
}
