import {
  AddOrganizationMemberPayload,
  CreateOrganizationGroupPayload,
  CreateOrganizationPayload,
  Organization,
  OrganizationGroup,
  OrganizationGroupId,
  OrganizationId,
  OrganizationMember,
  OrganizationRole,
  OrganizationTheme,
  SetOrganizationThemePayload,
  UpdateOrganizationGroupPayload,
  UserId,
} from 'interfaces';

import { api } from './api';

/**
 * Fetches all organizations for the current user.
 * @return A `Promise` that resolves to the list of organizations.
 */
export function getOrganizations() {
  return api.get<Organization[]>('/organizations');
}

/**
 * Fetches a single organization.
 * @param organizationPathOrId The organization path or ID.
 * @returns A `Promise` that resolves to the organization if found; otherwise null.
 */
export function getOrganization(organizationPathOrId: OrganizationId | string) {
  return api.get<Organization>(`/organizations/${organizationPathOrId}`);
}

/**
 * Fetches an organization's theme definition.
 * @param organizationId The organization ID.
 * @returns A `Promise` that resolves to the organization theme if found; otherwise null.
 */
export function getOrganizationTheme(organizationId: OrganizationId) {
  return api.get<OrganizationTheme | null>(`/organizations/${organizationId}/theme`);
}

/**
 * Fetches all available organization roles.
 * @returns A `Promise` that resolves to the list of organization roles.
 */
export function getOrganizationRoles() {
  return api.get<OrganizationRole[]>('/organizations/roles');
}

/**
 * Fetches all groups for the specified  organization.
 * @param organizationId The organization ID.
 * @returns A `Promise` that resolves to the list of groups for the organizations.
 */
export function getOrganizationGroups(organizationId: OrganizationId) {
  return api.get<OrganizationGroup[]>(`/organizations/${organizationId}/groups`);
}

/**
 * Fetches a single organization group.
 * @param organizationId The organization ID.
 * @param organizationGroupId The organization group ID.
 * @returns A `Promise` that resolves to the organization group if found; otherwise null.
 */
export function getOrganizationGroup(
  organizationId: OrganizationId,
  organizationGroupId: OrganizationGroupId
) {
  return api.get<OrganizationGroup>(
    `/organizations/${organizationId}/groups/${organizationGroupId}`
  );
}

/**
 * Creates a new organization.
 * @param payload The request payload used to create the organization.
 * @returns A `Promise` that resolves the new organization.
 */
export function createOrganization(payload: CreateOrganizationPayload) {
  return api.post<Organization>('/organizations', payload);
}

/**
 * Creates a new organization group.
 * @param organizationId The organization ID.
 * @param payload The request payload used to create the group.
 * @returns A `Promise` that resolves to the created organization group.
 */
export function createOrganizationGroup(
  organizationId: OrganizationId,
  payload: CreateOrganizationGroupPayload
) {
  return api.post<OrganizationGroup>(`/organizations/${organizationId}/groups`, payload);
}

/**
 * Deletes an existing organization group.
 * @param organizationId The organization ID.
 * @param organizationGroupId The organization group ID.
 */
export function deleteOrganizationGroup(
  organizationId: OrganizationId,
  organizationGroupId: OrganizationGroupId
) {
  return api.delete(`/organizations/${organizationId}/groups/${organizationGroupId}`);
}

/**
 * Updates an existing organization group.
 * @param organizationId The organization ID.
 * @param organizationRoleId The organization group ID.
 * @param payload The request payload used to update the organization group.
 * @returns A `Promise` that resolves to the updated organization group.
 */
export function updateOrganizationGroup(
  organizationId: OrganizationId,
  organizationGroupId: OrganizationGroupId,
  payload: UpdateOrganizationGroupPayload
) {
  return api.put<OrganizationGroup>(
    `/organizations/${organizationId}/groups/${organizationGroupId}`,
    payload
  );
}

/**
 * Adds a new member to an organization.
 * @param organizationId The organization ID.
 * @param payload The request payload used to add an organization member.
 * @return An empty `Promise`.
 */
export function addOrganizationMember(
  organizationId: OrganizationId,
  payload: AddOrganizationMemberPayload
) {
  return api.post<void>(`/organizations/${organizationId}/members`, payload);
}

/**
 * Fetches the organization members.
 * @param organizationId The organization ID.
 * @return A `Promise` of the list of organization members.
 */
export function getOrganizationMembers(organizationId: OrganizationId) {
  return api.get<OrganizationMember[]>(`/organizations/${organizationId}/members`);
}

/**
 * Removes a user from an organization.
 * @param organizationId The organization ID.
 * @param userId The user ID.
 * @return An empty `Promise`.
 */
export function removeOrganizationMember(organizationId: OrganizationId, userId: UserId) {
  return api.delete(`/organizations/${organizationId}/members/${userId}`);
}

/**
 * Sets the organization theme.
 * @param organizationId The organization ID.
 * @param payload The reuqest payload.
 * @returns A `Promise` of the updated theme.
 */
export function setOrganizationTheme(
  organizationId: OrganizationId,
  payload: SetOrganizationThemePayload
) {
  return api.post<OrganizationTheme>(`/organizations/${organizationId}/theme`, payload);
}
