import { Agent } from 'interfaces';

/**
 * Represents a Module parameter.
 */
export interface Parameter {
  /**
   * Gets the key name.
   */
  key: string;

  /**
   * Gets the description.
   */
  description: string;

  /**
   * Gets the default value for the parameter.
   */
  defaultValue: string;
}

/**
 * Represents the Module entity.
 */
export interface Module {
  /**
   * Gets the module ID.
   */
  readonly id: ModuleId;

  /**
   * Gets the module name.
   */
  name: string;

  /**
   * Gets the module description.
   */
  description: string | null;

  /**
   * Gets the module version.
   */
  version: string;

  /**
   * Gets the module's main executable's path.
   */
  mainExecutablePath: string;

  /**
   * Gets the module parameters.
   */
  parameters: string;

  /**
   * Gets whether the module is active for use in an agent.
   */
  isActive: boolean;

  /**
   * Gets the agents that support this module.
   */
  agents: Agent[];
}

/**
 * Represents the default module parameters.
 */
export const DEFAULT_MODULE_PARAMETERS = [
  { key: 'scenario', defaultValue: '{$scenario.name$}', description: '' },
  { key: 'model', defaultValue: '{$app.name$}', description: '' },
];

/**
 * Strongly-typed module ID.
 */
export type ModuleId = number;

/**
 * Request payload used to create a new module.
 */
export interface CreateModulePayload {
  /**
   * Gets the module name.
   */
  name: string;

  /**
   * Gets the module description.
   */
  description: string | null;

  /**
   * Gets the module version.
   */
  version: string;

  /**
   * Gets the module's main executable's path.
   */
  mainExecutablePath: string;

  /**
   * Gets the module parameters.
   */
  parameters: string;
}

/**
 * Request payload used to update a module.
 */
export type UpdateModulePayload = CreateModulePayload;
