import { useCallback, useState } from 'react';

import {
  createDescendantManagerContext,
  useControlledState,
  useIds,
  useUnmountEffect,
} from 'hooks';
import { createContext } from 'utils';

export const [
  AccordionDescendantManagerContext,
  useAccordionDescendantManagerContext,
  useAccordionDescendantManager,
  useAccordionDescendant,
] = createDescendantManagerContext();

export type AccordionContext = UseAccordionProviderReturn;

export const [AccordionContextProvider, useAccordionContext] = createContext<AccordionContext>({
  name: 'AccordionContext',
});

export type AccordionPanelContext = UseAccordionPanelProviderReturn;

export const [AccordionPanelContextProvider, useAccordionPanelContext] =
  createContext<AccordionPanelContext>({});

export type UseAccordionProviderOpts = {
  /**
   * The expanded panel index.
   */
  expandedIndex?: number;

  /**
   * The initial expanded panel index.
   */
  defaultExpandedIndex?: number;

  /**
   * Callback fired when the expanded index changes.
   */
  onExpandedIndexChange?(value: number): void;
};

export function useAccordionContextProvider(opts: UseAccordionProviderOpts) {
  const { expandedIndex: expandedIndexProp, onExpandedIndexChange } = opts;

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [expandedIndex, setExpandedIndex] = useControlledState({
    value: expandedIndexProp,
    defaultValue: -1,
    onChange: onExpandedIndexChange,
  });

  useUnmountEffect(() => {
    setFocusedIndex(-1);
  });

  return {
    expandedIndex,
    focusedIndex,
    setExpandedIndex,
    setFocusedIndex,
  };
}

export type UseAccordionProviderReturn = ReturnType<typeof useAccordionContextProvider>;

export type UseAccordionPanelProviderOpts = {
  /**
   * Whether the panel is disabled or not.
   */
  disabled?: boolean;

  /**
   * The panel ID.
   */
  id?: string;
};

export function useAccordionPanelContextProvider(opts: UseAccordionPanelProviderOpts) {
  const { id, disabled = false } = opts;

  const [headerId, contentId] = useIds(id, 'accordion-header', 'accordion-content');
  const { expandedIndex, setExpandedIndex } = useAccordionContext();
  const { descendantManager, register, index } = useAccordionDescendant({
    disabled,
  });

  const isOpen = index === -1 ? false : expandedIndex === index;

  const setOpen = useCallback(
    (isOpen: boolean) => {
      setExpandedIndex(isOpen ? index : -1);
    },
    [index, setExpandedIndex]
  );

  return {
    index,
    isOpen,
    headerId,
    contentId,
    descendantManager,
    register,
    setOpen,
  };
}

export type UseAccordionPanelProviderReturn = ReturnType<typeof useAccordionPanelContextProvider>;
