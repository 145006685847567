import { ComponentProps, MouseEvent, ReactChild, ReactElement, ReactNode, forwardRef } from 'react';
import { FiChevronDown, FiX } from 'react-icons/fi';

import { IconButton } from 'components/button';
import { baseInputStyles } from 'components/input';
import { styled } from 'stitches';

const ControlInputRoot = styled('div', baseInputStyles, {
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  display: 'flex',
  gap: '$3',

  overflow: 'hidden',

  '[aria-expanded] &, &[aria-expanded]': {
    borderColor: '$primary-500',
    boxShadow: '0 0 0 1px $colors$primary-500',
  },
});

const ControlInputContent = styled('div', {
  textOverflow: 'ellipsis',
  userSelect: 'none',
  overflow: 'hidden',
  flexWrap: 'wrap',
  display: 'flex',
  flex: '1 1 100%',
  gap: '$2',
});

const ControlInputPlaceholder = styled(ControlInputContent, {
  color: '$neutral-400',
});

const ControlInputAdornment = styled('div', {
  alignItems: 'center',
  alignSelf: 'stretch',
  display: 'flex',
  gap: '$1',
});

const ControlInputClearButton = styled(IconButton, {
  padding: '2px',
  fontSize: '$xs',
  border: 'none',
});

const ControlInputDropdownIndicator = styled(FiChevronDown, {
  color: '$neutral-300',

  [`${ControlInputRoot}:hover &`]: {
    color: '$neutral-500',
  },

  '[aria-expanded] &': {
    color: '$primary-500 !important',
  },
});

export const ControlInputTextbox = styled('input', {
  // Reset
  appearance: 'none',
  outline: 'none',
  padding: 0,
  margin: 0,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',

  flexGrow: 1,

  width: 0,
  minWidth: '30px',

  lineHeight: 1,

  color: '$neutral-600',

  '&::placeholder': {
    color: '$neutral-400',
  },
});

type ControlInputRootProps = ComponentProps<typeof ControlInputRoot>;

export type ControlInputState = ControlInputRootProps['state'];

export type ControlInputProps = Omit<ControlInputRootProps, 'placeholder'> & {
  /**
   * The control content.
   */
  children?: ReactNode;

  /**
   * The control placeholder content.
   */
  placeholder?: ReactChild;

  /**
   * Whether to display the dropdown indicator.
   * @default false
   */
  dropdownIndicator?: boolean;

  /**
   * Whether to display the clear button.
   * @default false
   */
  clearable?: boolean;

  /**
   * Any adornment content to be rendered at the start of the control.
   */
  startAdornment?: ReactNode;

  /**
   * Callback invoked when the clear button is clicked.
   */
  onClear?(event: MouseEvent): void;
};

/**
 * Component that replicates the `Input` component styles, without being an actual input element.
 * Useful for dropdown or select components that want to display values to the user in a familiar
 * and consistent way.
 */
export const ControlInput = forwardRef<HTMLDivElement, ControlInputProps>(function ControlInput(
  props,
  forwardedRef
) {
  const { children, clearable, placeholder, startAdornment, dropdownIndicator, onClear, ...rest } =
    props;

  const handleClearClick = (event: MouseEvent) => {
    event.stopPropagation();
    onClear?.(event);
  };

  const endAdornment: ReactElement[] = [];
  if (clearable) {
    endAdornment.push(
      <ControlInputClearButton
        key="clear-button"
        type="button"
        tabIndex={-1}
        aria-label="Clear"
        onClick={handleClearClick}
      >
        <FiX aria-hidden="true" />
      </ControlInputClearButton>
    );
  }

  if (dropdownIndicator) {
    endAdornment.push(
      <ControlInputDropdownIndicator key="dropdown-indicator" aria-hidden="true" />
    );
  }

  return (
    <ControlInputRoot
      ref={forwardedRef}
      // TODO: Remove any cast
      {...(rest as any)}
    >
      {!!startAdornment && <ControlInputAdornment>{startAdornment}</ControlInputAdornment>}

      {children ? (
        <ControlInputContent>{children}</ControlInputContent>
      ) : (
        <ControlInputPlaceholder>{placeholder || '\u00A0'}</ControlInputPlaceholder>
      )}

      {!!endAdornment.length && <ControlInputAdornment>{endAdornment}</ControlInputAdornment>}
    </ControlInputRoot>
  );
});
