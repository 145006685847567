import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import { useNavigate } from 'react-router-dom';

import { useApplicationWindowCache, useCurrentApplication } from 'app';
import {
  Accordion,
  AccordionPanel,
  AccordionPanelContent,
  AccordionPanelHeader,
} from 'components/accordion';
import { useAccordionPanelContext } from 'components/accordion/AccordionContext';
import { Flex, FlexItem } from 'components/flex';
import { Link } from 'components/link';
import { List, ListItem, ListItemContent } from 'components/list';
import { ResourceLink } from 'components/resource-link';
import { Text } from 'components/text';

import { useApplicationSuitesQuery } from 'data/application-suites';
import { useApplicationsQuery } from 'data/applications';
import { useOrganizationThemeQuery } from 'data/organizations';
import { useAssignedWorkflowResources, useWorkflowsQuery } from 'data/workflows';
import {
  Application,
  ApplicationId,
  OrganizationId,
  OrganizationTheme,
  Resource,
  Workflow,
} from 'interfaces';
import { ApplicationSuiteMember } from 'interfaces/application-suitemember';

import { DecisioWindowMessage, DecisioWindowMessageType } from 'interfaces/window-message';
import { styled } from 'stitches';

const StyledResourceLink = styled(ResourceLink, {
  color: '$neutral-900',
  textDecoration: 'none',

  alignItems: 'center',
  display: 'flex',
  gap: '$3',
});

const StyledApplicationLink = styled(Link, {
  color: '$neutral-900',
  fontSize: '$lg',
  letterSpacing: '$xs',

  '&:hover': {
    fontWeight: '$semibold',
  },

  variants: {
    current: {
      true: {
        fontWeight: '$semibold',
      },
    },
  },
});

const StyledDashboardLink = styled(Link, {
  color: '$neutral-600',

  '&:hover': {
    fontWeight: '$semibold',
  },
});

export function AppSuites(props: { organizationId: OrganizationId }) {
  const { organizationId } = props;

  const { organizationTheme } = useOrganizationThemeQuery(organizationId);
  const { applicationSuites } = useApplicationSuitesQuery(organizationId);

  const currentApplication = useCurrentApplication(false);
  const applications = useApplicationsQuery().applications.filter(
    (app) => app.organization.id === organizationId
  );

  const applicationsByIdMap = new Map<ApplicationId, Application>(
    applications.map((app) => [app.id, app])
  );

  const allOrganizationAppMemberIdsSet = new Set<ApplicationId>(
    applicationSuites
      ?.flatMap((suite) => suite.applicationSuiteMembers)
      .map((appMember) => appMember.applicationId)
      .filter((_): _ is ApplicationId => _ !== undefined)
  );
  const allDefaultApplications = applications
    .filter((app) => !allOrganizationAppMemberIdsSet.has(app.id))
    .sort((app1, app2) => app1.name.localeCompare(app2.name));

  return (
    <>
      {applicationSuites?.map((appSuite) => (
        <AppSuiteAccordion
          key={`${organizationId}-${appSuite.id}`}
          organizationTheme={organizationTheme}
          appSuiteName={appSuite.name}
          appSuiteMembers={appSuite.applicationSuiteMembers}
          applicationsMap={applicationsByIdMap}
          isInitiallyOpen={
            Boolean(currentApplication) &&
            appSuite.applicationSuiteMembers.findIndex(
              (member) => member.applicationId === currentApplication.id
            ) >= 0
          }
        />
      ))}

      <AppSuiteAccordion
        key={`${organizationId}-Default`}
        organizationTheme={organizationTheme}
        appSuiteName={'Default'}
        appSuiteMembers={allDefaultApplications.map((app) => {
          return {
            applicationId: app.id,
          } as ApplicationSuiteMember;
        })}
        applicationsMap={applicationsByIdMap}
        isInitiallyOpen={(applicationSuites?.length ?? 0) === 0}
      />
    </>
  );
}

function AppSuiteAccordion(props: {
  organizationTheme: OrganizationTheme;
  appSuiteName: string;
  appSuiteMembers: ApplicationSuiteMember[];
  applicationsMap: Map<ApplicationId, Application>;
  isInitiallyOpen?: boolean;
}) {
  const { organizationTheme, appSuiteName, appSuiteMembers, applicationsMap, isInitiallyOpen } =
    props;
  const applications = appSuiteMembers
    .map((m) => applicationsMap.get(m.applicationId))
    .filter((_): _ is Application => Boolean(_));

  const StyledApplicationSuiteText = styled(Text, {
    color: organizationTheme.primaryColor,
  });

  const [currentAppSuiteExpanded, setCurrentAppSuiteExpanded] = useState(isInitiallyOpen);

  return (
    <Accordion
      expandedIndex={currentAppSuiteExpanded ? 0 : undefined}
      onExpandedIndexChange={(_) => {
        setCurrentAppSuiteExpanded(false);
      }}
    >
      <AccordionPanel>
        <ChevronToggleAccordionPanelHeader hideChevron={applications.length === 0}>
          <StyledApplicationSuiteText size="xl" variant="title2">
            {appSuiteName}
          </StyledApplicationSuiteText>
        </ChevronToggleAccordionPanelHeader>
        <AccordionPanelContent>
          {applications.map((app) => (
            <AppAccordion key={app.id} application={app} />
          ))}
        </AccordionPanelContent>
      </AccordionPanel>
    </Accordion>
  );
}

function AppAccordion(props: { application: Application }) {
  const { application } = props;
  const { workflows } = useWorkflowsQuery(application.id);
  const currentApplication = useCurrentApplication(false);
  const [getCachedAppWindow, setCachedAppWindow] = useApplicationWindowCache();
  const navigate = useNavigate();

  const [currentAppExpanded, setCurrentAppExpanded] = useState(
    currentApplication?.id === application.id
  );

  const navigateTo = useCallback(
    (path: string, searchParams?: URLSearchParams) => {
      function getOrOpenAppWindow(url: URL) {
        let localAppWindow: Window | null = null;
        const cachedWindow = getCachedAppWindow(application.id);

        if (cachedWindow) {
          // Accessing `appWindow.location` will expectedly fail with a cross-origin error if the
          // origin of the window has changed.
          try {
            if (!cachedWindow.closed && cachedWindow.location.origin === window.location.origin) {
              localAppWindow = cachedWindow;
            }
          } catch (e) {
            // The child window origin likely changed.
            console.error(e);
          }
        }

        if (!localAppWindow) {
          // Attempt to open in new window/tab with the application ID being the name.
          // If a window/tab already exists with that name, that tab then should be opened.
          localAppWindow = window.open(url, application.id.toString());
          setCachedAppWindow(application.id, localAppWindow);
        }

        return localAppWindow;
      }

      function buildUrl() {
        const url = new URL(window.location.origin);
        url.pathname = path;
        searchParams?.forEach((value, key) => {
          url.searchParams.set(key, value);
        });
        return url;
      }

      const url = buildUrl();
      const relativeUrl = `${url.pathname}${url.search}`;
      const localAppWnd = getOrOpenAppWindow(url);
      if (localAppWnd) {
        localAppWnd.postMessage(
          new DecisioWindowMessage(DecisioWindowMessageType.TaskOpen, relativeUrl),
          window.location.origin
        );
        localAppWnd.focus();
      } else {
        console.warn(
          'Failed to get or open application window for application. Navigating within current.',
          application
        );
        navigate(relativeUrl);
      }
    },
    [application, getCachedAppWindow, setCachedAppWindow, navigate]
  );

  return (
    <Accordion
      key={application.id}
      expandedIndex={currentAppExpanded ? 0 : undefined}
      onExpandedIndexChange={() => setCurrentAppExpanded(false)}
    >
      <AccordionPanel>
        <ChevronToggleAccordionPanelHeader hideChevron={workflows.length === 0}>
          <StyledApplicationLink
            target={
              !currentApplication || currentApplication.id === application.id ? '_self' : '_blank'
            }
            to={application.fullPath}
            onClick={(e) => {
              // Stop the accordion also receiving the click and opening
              e.stopPropagation();

              if (currentApplication && currentApplication.id !== application.id) {
                // Ensure we handle the navigating, not the link
                e.preventDefault();
                navigateTo(application.fullPath);
              }
            }}
            current={currentApplication && currentApplication.id === application.id}
          >
            {application.name}
          </StyledApplicationLink>
        </ChevronToggleAccordionPanelHeader>
        <AccordionPanelContent>
          {workflows.map((workflow) => (
            <DashboardAccordion
              key={workflow.id}
              application={application}
              workflow={workflow}
              navigateTo={navigateTo}
            />
          ))}
        </AccordionPanelContent>
      </AccordionPanel>
    </Accordion>
  );
}

function DashboardAccordion(props: {
  application: Application;
  workflow: Workflow;
  navigateTo(path: string, searchParams?: URLSearchParams): void;
}) {
  const { application, workflow, navigateTo } = props;
  const currentApplication = useCurrentApplication(false);
  const [hasChildren, setHasChildren] = useState(false);

  const dashboardUrl = `${application.fullPath}/dashboard`;

  return (
    <Accordion key={workflow.id}>
      <AccordionPanel>
        <ChevronToggleAccordionPanelHeader hideChevron={!hasChildren}>
          <StyledDashboardLink
            to={dashboardUrl}
            target={
              !currentApplication || currentApplication.id === application.id ? '_self' : '_blank'
            }
            onClick={(e) => {
              // Stop the accordion also receiving the click and opening
              e.stopPropagation();

              if (currentApplication && currentApplication.id !== application.id) {
                // Ensure we handle the navigating, not the link
                e.preventDefault();
                navigateTo(dashboardUrl);
              }
            }}
          >
            {workflow.name}
          </StyledDashboardLink>
        </ChevronToggleAccordionPanelHeader>
        <AccordionPanelContent>
          <DashboardMenuLevelAccordion
            application={application}
            workflow={workflow}
            setDashboardHasMenuItems={() => setHasChildren(true)}
            navigateTo={navigateTo}
          />
        </AccordionPanelContent>
      </AccordionPanel>
    </Accordion>
  );
}

function DashboardMenuLevelAccordion(props: {
  application: Application;
  workflow: Workflow;
  setDashboardHasMenuItems(): void;
  navigateTo(path: string, searchParams?: URLSearchParams): void;
}) {
  const { application, workflow, setDashboardHasMenuItems, navigateTo } = props;
  const { assignedResources } = useAssignedWorkflowResources(application.id, workflow.id);

  useEffect(() => {
    if (assignedResources.length > 0) {
      setDashboardHasMenuItems();
    }
  }, [workflow.id, assignedResources.length, setDashboardHasMenuItems]);

  return (
    <List hideDivider>
      {assignedResources.map((workflowResource) => (
        <ListItem key={workflowResource.resourceId}>
          <ListItemContent>
            <DashboardMenuResourceLink
              key={workflowResource.resourceId}
              application={application}
              workflow={workflow}
              workflowResource={workflowResource}
              navigateTo={navigateTo}
            />
          </ListItemContent>
        </ListItem>
      ))}
    </List>
  );
}

function DashboardMenuResourceLink(props: {
  application: Application;
  workflow: Workflow;
  workflowResource: Resource;
  navigateTo(path: string, searchParams?: URLSearchParams): void;
}) {
  const { application, workflow, workflowResource, navigateTo } = props;
  const currentApplication = useCurrentApplication(false);
  const resourceLinkRef = useRef<HTMLAnchorElement>(null);

  return (
    <StyledResourceLink
      ref={resourceLinkRef}
      applicationPath={application.fullPath}
      queryParams={{ workflowId: workflow.id.toString() }}
      item={workflowResource}
      target={!currentApplication || currentApplication.id === application.id ? '_self' : '_blank'}
      onClick={(e) => {
        if (currentApplication && currentApplication.id !== application.id) {
          // Ensure we handle the navigating, not the link
          e.preventDefault();
          navigateTo(
            resourceLinkRef.current?.pathname!,
            new URLSearchParams(resourceLinkRef.current?.search)
          );
        }
      }}
    />
  );
}

function ChevronToggleAccordionPanelHeader(props: { children?: ReactNode; hideChevron?: boolean }) {
  const { children, hideChevron } = props;
  const { isOpen } = useAccordionPanelContext();

  return (
    <AccordionPanelHeader>
      <Flex align="center" gap="3">
        <FlexItem shrink="0" css={{ visibility: hideChevron ? 'hidden' : 'initial' }}>
          {isOpen ? <FiChevronDown /> : <FiChevronRight />}
        </FlexItem>
        <FlexItem>{children}</FlexItem>
      </Flex>
    </AccordionPanelHeader>
  );
}
