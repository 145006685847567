import Fuse from 'fuse.js';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { SearchParserResult, parse } from 'search-query-parser';

import { useFuse } from './useFuse';

export type CollectionSortDir = 'asc' | 'desc';
export type CollectionSortFn<T> = (a: T, b: T) => number;

export type CollectionSortOption<T> = {
  key: keyof T | string;

  label?: string;

  isDefault?: boolean;

  initialDir?: CollectionSortDir;

  sortFn?: CollectionSortFn<T>;
};

export type StateFilter<T> = {
  key: `is:${string}`;
  predicate(item: T, index: number, array: T[]): boolean;
};

export type StandardFilter<T> = {
  key: string;
  predicate(item: T, value: any, index: number, array: T[]): boolean;
  valueTransformer?(value: string, index: number): any;
};

export type CollectionFilter<T> = StateFilter<T> | StandardFilter<T>;

export type CollectionQuery = {
  filters: Map<string, Set<string>>;
  sortProp?: string;
  sortDir?: CollectionSortDir;
  page?: number;
  perPage?: number;
};

export type UseCollectionListOptions<T> = {
  source: T[] | null | undefined;
  queryString: string | null | undefined;
  searchKeys?: string[];
  searchThreshold?: number;
  searchExtended?: boolean;
  page?: number;
  perPage?: number;
  sorting?: CollectionSortOption<T>[];
  filters?: CollectionFilter<T>[];
  hiddenItemIds?: number[];
};

/**
 * Hook that performs advanced searching and filtering on a collection.
 * @param options The hook options.
 */
export function useCollectionList<T>(options: UseCollectionListOptions<T>) {
  const {
    source,
    searchKeys,
    searchThreshold = 0.1,
    searchExtended = true,
    filters = [],
    page,
    perPage,
    sorting,
    queryString,
    hiddenItemIds,
  } = options;

  const fuse = useFuse({
    items: source,
    threshold: searchThreshold,
    shouldSort: true,
    includeScore: true,
    distance: 200,
    useExtendedSearch: searchExtended,
    ...(searchKeys && {
      keys: searchKeys,
    }),
    // sortFn: (a, b) => {
    //   const aScore = parseFloat(a.score.toFixed(2));
    //   const bScore = parseFloat(b.score.toFixed(2));
    //   return aScore === bScore
    //     ? items[a.idx].name.localeCompare(items[b.idx].name)
    //     : aScore - bScore;
    // },
  });

  const parsedQuery = useMemo(() => {
    if (!queryString) {
      return null;
    }

    const keywords = new Set(['is', ...filters.map((f) => f.key)]);
    if (sorting) {
      keywords.add('sort');
    }

    return parse(queryString, {
      keywords: Array.from(keywords),
      tokenize: true,
      alwaysArray: true,
    }) as SearchParserResult;
  }, [filters, sorting, queryString]);

  const query = useMemo(() => {
    const query: CollectionQuery = {
      filters: new Map(),
      page: page ? Math.max(1, page) : undefined,
      perPage: perPage ? Math.max(1, perPage) : undefined,
    };

    if (parsedQuery?.text) {
      query.filters.set('text', new Set([(parsedQuery.text as string[]).join(' ')]));
    }

    filters.forEach((filter) => {
      if (isStateFilter(filter)) {
        let filterKey = filter.key.split(':')[1];
        if (parsedQuery?.['is']?.includes(filterKey)) {
          const set = query.filters.get('is') || new Set();
          set.add(filterKey);
          query.filters.set('is', set);
        }
      } else {
        if (parsedQuery?.[filter.key]) {
          query.filters.set(filter.key, new Set(parsedQuery?.[filter.key]));
        }
      }
    });

    if (sorting) {
      let sortOpt = sorting.find((x) => x.isDefault) || sorting[0];
      let sortDir: CollectionSortDir = sortOpt?.initialDir || 'asc';
      let sortProp = String(sortOpt?.key || '');

      const sortQuery = parsedQuery?.sort?.[0];
      if (sortQuery) {
        const sepIndex = sortQuery.lastIndexOf('-');
        if (sepIndex === -1) {
          sortProp = sortQuery;
        } else {
          sortProp = sortQuery.substr(0, sepIndex);
          sortDir = sortQuery.substr(sepIndex + 1);
        }
      }

      query.sortDir = sortDir;
      query.sortProp = sortProp;
    }

    return query;
  }, [filters, parsedQuery, page, perPage, sorting]);

  const { items, isFiltered, pageCount } = useMemo(() => {
    if (!source) {
      return { items: [], isFiltered: false, pageCount: 0 };
    }

    let items = source;
    let isFiltered = false;
    let pageCount = 1;

    query.filters.forEach((value, key) => {
      if (key === 'text') {
        if (searchKeys) {
          const searchPatterns = Array.from(value).reduce<Fuse.Expression[]>(
            (memo, val) => [...memo, ...searchKeys.map<Fuse.Expression>((key) => ({ [key]: val }))],
            []
          );

          items = fuse.search<T>({ $or: searchPatterns }).map((x) => x.item);
        } else {
          items = fuse.search<T>(Array.from(value)[0]).map((x) => x.item);
        }
      } else if (key === 'is') {
        Array.from(value).forEach((f) => {
          const filter = filters.find((x) => x.key === `is:${f}`) as StateFilter<T>;
          if (filter) {
            items = items.filter(filter.predicate);
          }
        });
      } else {
        const filter = filters.find((x) => x.key === key) as StandardFilter<T>;
        if (filter) {
          let values = Array.from(query.filters.get(filter.key) || []);
          if (values.length >= 1) {
            if (filter.valueTransformer) {
              values = values.map(filter.valueTransformer);
            }

            items = items.filter((item, index, array) =>
              values!.some((value) => filter.predicate(item, value, index, array))
            );
          }
        }
      }
    });

    isFiltered = items !== source;

    if (query.sortProp) {
      const sortDef = sorting?.find((x) => x.key === query.sortProp);
      if (sortDef?.sortFn) {
        items = orderBy(items, [sortDef.sortFn], query.sortDir) as T[];
      } else {
        items = orderBy(items, [query.sortProp], query.sortDir);
      }
    }

    if (query.page && query.perPage) {
      pageCount = Math.ceil(items.length / query.perPage);
      items = items.slice(query.perPage * (query.page - 1), query.perPage * query.page);
    }

    return { items, isFiltered, pageCount };
  }, [filters, fuse, searchKeys, source, sorting, query]);

  const filteredItems = items.filter((item) => !hiddenItemIds?.includes((item as any).id));

  return {
    items: filteredItems,
    pageCount,
    isFiltered,
    query,
    parsedQuery,
  };
}

function isStateFilter<T>(filter: CollectionFilter<T>): filter is StateFilter<T> {
  return filter.key.startsWith('is:');
}
