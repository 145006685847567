import { MouseEvent, ReactElement, RefObject, useCallback, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';

import { DropdownButton } from 'components/dropdown-button';
import { Menu, MenuList, MenuTrigger, Placement } from 'components/menu';
import { Text } from 'components/text';
import { CollectionFilter, useCollectionList, useOpenState } from 'hooks';

import { SelectMenuItem } from './SelectMenuItem';
import { SelectMenuSearch } from './SelectMenuSearch';

export type { Placement };

export type RenderTriggerOptions = {
  isActive: boolean;
};

export type SelectMenuProps<T> = {
  source?: T[];

  value?: any | any[];

  label?: string;

  filters?: CollectionFilter<T>[];

  boundaryRef?: RefObject<HTMLElement>;

  labelProp?: string | ((item: T) => string);

  keyProp?: string | ((item: T) => string);

  valueProp?: string | ((item: T) => any);

  multiple?: boolean;

  placement?: Placement;

  selectionMode?: 'inclusive' | 'exclusive';

  allowSelectAll?: boolean;

  allowInvertSelection?: boolean;

  allowFocusSelect?: boolean;

  closeOnClick?: boolean | ((e: MouseEvent) => boolean);

  /**
   * Whether the options can be searched.
   * @default false
   */
  searchable?: boolean;

  /**
   * Whether the search should be cleared when the menu is closed.
   * @default true
   */
  clearSearchOnClose?: boolean;

  renderTrigger?(options: RenderTriggerOptions): ReactElement;

  onClear?(): void;

  onChange?(value: any, isInverted?: boolean): void;
};

export function SelectMenu<T>(props: SelectMenuProps<T>) {
  const {
    source,
    label,
    value,
    labelProp,
    keyProp,
    valueProp = (item) => item,
    multiple = false,
    selectionMode = 'inclusive',
    allowFocusSelect = false,
    allowSelectAll = false,
    allowInvertSelection = false,
    placement,
    filters,
    closeOnClick,
    searchable = false,
    clearSearchOnClose = true,
    renderTrigger,
    onClear,
    onChange,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const { isOpen, open, close } = useOpenState({});
  const { items, isFiltered } = useCollectionList({
    source,
    filters,
    queryString: searchValue,
    searchKeys: typeof labelProp === 'function' ? [] : labelProp ? [labelProp] : [],
    searchThreshold: 0.2,
  });

  const isActive = value ? (Array.isArray(value) ? value.length > 0 : true) : false;

  const handleItemSelect = useCallback(
    (itemValue: any) => {
      let nextValue: any | any[];
      if (multiple) {
        if (!Array.isArray(value)) {
          nextValue = [itemValue];
        } else {
          nextValue = [...value];
          const valueIndex = value.indexOf(itemValue);
          if (valueIndex === -1) {
            nextValue.push(itemValue);
          } else {
            nextValue.splice(valueIndex, 1);
          }
        }
      } else {
        nextValue = itemValue;
      }

      onChange?.(nextValue);
    },
    [multiple, value, onChange]
  );

  const handleAllSelect = useCallback(() => {
    if (selectionMode === 'inclusive') {
      const nextValue = source?.map((item) => getObjectPropValue(item, valueProp));
      onChange?.(nextValue);
    } else {
      onChange?.([]);
    }
  }, [source, selectionMode, valueProp, onChange]);

  const handleInvertSelection = useCallback(() => {
    onChange?.([], true);
  }, [onChange]);

  const handleFocusSelect = useCallback(
    (value: any) => {
      if (selectionMode === 'inclusive') {
        onChange?.([value]);
      } else {
        const nextValue = source
          ?.map((item) => getObjectPropValue(item, valueProp))
          .filter((v) => v !== value);
        onChange?.(nextValue);
      }
    },
    [source, selectionMode, valueProp, onChange]
  );

  const handleMenuChange = (isOpen: boolean) => {
    if (isOpen) {
      open();
    } else {
      close();
      if (clearSearchOnClose) {
        setSearchValue('');
      }
    }
  };

  const handleClearButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    onClear?.();

    if (isOpen) {
      close();
    }
  };

  const children = useMemo(() => {
    let message;
    if (!items.length) {
      message = isFiltered ? 'No matching items' : 'No items available';
    }

    if (message) {
      return (
        <Text as="div" variant="secondary" align="center" gutter="lg">
          {message}
        </Text>
      );
    }

    const children =
      items.map((item, index) => {
        const label = getObjectPropValue(item, labelProp);
        const itemValue = getObjectPropValue(item, valueProp);
        const key = getObjectPropValue(item, keyProp) || itemValue || index;

        let isSelected;
        if (multiple) {
          if (selectionMode === 'inclusive') {
            isSelected = Array.isArray(value) ? value.includes(itemValue) : value === itemValue;
          } else {
            isSelected = Array.isArray(value) ? !value.includes(itemValue) : true;
          }
        } else {
          isSelected = value === itemValue;
        }

        return (
          <SelectMenuItem
            isChecked={isSelected}
            closeOnClick={closeOnClick ?? !multiple}
            focusSelect={allowFocusSelect}
            key={key}
            value={itemValue}
            onSelect={handleItemSelect}
            onFocusSelect={handleFocusSelect}
          >
            {label}
          </SelectMenuItem>
        );
      }) || [];

    if (multiple && allowSelectAll) {
      const isSelected = selectionMode === 'inclusive' ? false : !value?.length;

      children.unshift(
        <SelectMenuItem key="all" isChecked={isSelected} onSelect={handleAllSelect}>
          All
        </SelectMenuItem>
      );
    }

    if (multiple && allowInvertSelection) {
      children.unshift(
        <SelectMenuItem key="invert" onSelect={handleInvertSelection}>
          Invert Selection
        </SelectMenuItem>
      );
    }

    return <MenuList>{children}</MenuList>;
  }, [
    items,
    multiple,
    allowSelectAll,
    isFiltered,
    labelProp,
    valueProp,
    keyProp,
    closeOnClick,
    allowFocusSelect,
    handleItemSelect,
    handleFocusSelect,
    selectionMode,
    value,
    handleAllSelect,
    allowInvertSelection,
    handleInvertSelection,
  ]);

  return (
    <MenuTrigger placement={placement} open={isOpen} onChange={handleMenuChange}>
      {renderTrigger ? (
        renderTrigger({ isActive })
      ) : (
        <DropdownButton active={isActive}>
          {isActive ? <FiX onClick={handleClearButtonClick} /> : null}
          {label}
        </DropdownButton>
      )}

      <Menu>
        {searchable && (items.length || isFiltered) ? (
          <SelectMenuSearch value={searchValue} onChange={setSearchValue} />
        ) : null}
        {children}
      </Menu>
    </MenuTrigger>
  );
}

function getObjectPropValue<T>(item: T, prop?: string | ((item: T) => any)): any {
  return prop ? (typeof prop === 'function' ? prop(item) : (item as any)[prop]) : null;
}
