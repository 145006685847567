import { ComponentPropsWithRef, forwardRef } from 'react';

import { Flex } from 'components/flex';

import { LicensedApplicationCountReachedAlert } from './LicensedApplicationCountReachedAlert';
import { LicenseExpiredAlert } from './LicenseExpiredAlert';
import { LicenseExpiringSoonAlert } from './LicenseExpiringSoonAlert';

export type LicenseAlertStackProps = {
  applicationCount: number;
} & ComponentPropsWithRef<typeof Flex>;

export const LicenseAlertStack = forwardRef<HTMLDivElement, LicenseAlertStackProps>(
  function LicenseAlertStack(props, forwardedRef) {
    const { applicationCount, ...rest } = props;

    return (
      <Flex
        ref={forwardedRef}
        gap={2}
        direction={'column'}
        align="normal"
        css={{ width: '100%' }}
        {...rest}
      >
        <LicenseExpiredAlert />
        <LicensedApplicationCountReachedAlert applicationCount={applicationCount} />
        <LicenseExpiringSoonAlert />
      </Flex>
    );
  }
);
