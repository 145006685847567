import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as organizations from 'api/organizations';
import {
  ApplicationId,
  CreateOrganizationGroupPayload,
  CreateOrganizationPayload,
  Organization,
  OrganizationGroup,
  OrganizationGroupId,
  OrganizationId,
  SetOrganizationThemePayload,
  UpdateOrganizationGroupPayload,
} from 'interfaces';

import { useApplicationGroupsQuery } from './applications';

/**
 * Hook that queries the organizations.
 * @returns The organizations.
 */
export function useOrganizationsQuery() {
  const { data } = useQuery(['organizations'], () => organizations.getOrganizations());

  return {
    organizations: data!,
  };
}

export function useOrganizationRolesQuery() {
  const { data } = useQuery([`organization-roles`], () => organizations.getOrganizationRoles());

  return {
    organizationRoles: data!,
  };
}

export function useOrganizationGroupsQuery(organizationId: OrganizationId) {
  const { data } = useQuery(['organizations', organizationId, 'groups'], () =>
    organizations.getOrganizationGroups(organizationId)
  );

  return {
    organizationGroups: data!,
  };
}

export function useCreateOrganizationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateOrganizationPayload) => organizations.createOrganization(payload),
    {
      onSuccess: (organization: Organization) => {
        queryClient.setQueryData<Organization[]>(['organizations'], (prevValue) => {
          return [...(prevValue || []), organization];
        });

        queryClient.invalidateQueries(['organizations']);
      },
    }
  );
}

/**
 * Hook that returns a mutation used to create an organization group.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useCreateOrganizationGroupMutation(organizationId: OrganizationId) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateOrganizationGroupPayload) =>
      organizations.createOrganizationGroup(organizationId, payload),
    {
      onSuccess: (result) => {
        queryClient.setQueryData<OrganizationGroup[]>(
          ['organizations', organizationId, 'groups'],
          (prevValue) => [...(prevValue || []), result]
        );

        queryClient.invalidateQueries(['organizations', organizationId, 'groups']);
      },
    }
  );
}

export type UseUpdateOrganizationGroupMutationPayload = UpdateOrganizationGroupPayload & {
  organizationGroupId: OrganizationGroupId;
};

/**
 * Hook that returns a mutation used to update an organization group.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useUpdateOrganizationGroupMutation(organizationId: OrganizationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ organizationGroupId, ...payload }: UseUpdateOrganizationGroupMutationPayload) =>
      organizations.updateOrganizationGroup(organizationId, organizationGroupId, payload),
    {
      onSuccess: (result) => {
        queryClient.setQueryData<OrganizationGroup[]>(
          ['organizations', organizationId, 'groups'],
          (prevValue) => prevValue?.map((x) => (x.id === result.id ? result : x)) ?? [result]
        );

        queryClient.invalidateQueries(['organizations', organizationId, 'groups']);
      },
    }
  );
}

export type UseDeleteOrganizationGroupMutationPayload = {
  organizationGroupId: OrganizationGroupId;
};

/**
 * Hook that returns a mutation used to delete an organization group.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useDeleteOrganizationGroupMutation(organizationId: OrganizationId) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ organizationGroupId }: UseDeleteOrganizationGroupMutationPayload) =>
      organizations.deleteOrganizationGroup(organizationId, organizationGroupId),
    {
      onSuccess: (_, { organizationGroupId }) => {
        queryClient.setQueryData<OrganizationGroup[]>(
          ['organizations', organizationId, 'groups'],
          (prevValue) => prevValue?.filter((x) => x.id !== organizationGroupId) ?? []
        );

        queryClient.invalidateQueries(['organizations', organizationId, 'groups']);
      },
    }
  );

  return mutation;
}

/**
 * Hook that returns organization groups that are assigned to an application.
 * @param organizationId The organization ID.
 * @param applicationId The application ID.
 * @returns The `OrganizationGroup` items that are assigned to an application.
 */
export function useAssignableApplicationGroups(
  organizationId: OrganizationId,
  applicationId: ApplicationId
) {
  const { applicationGroups } = useApplicationGroupsQuery(applicationId);
  const { organizationGroups } = useOrganizationGroupsQuery(organizationId);

  const assignableGroups = organizationGroups.filter(
    (x) => x.requiresResourceAssignment && applicationGroups.includes(x.id)
  );

  return {
    assignableGroups,
  };
}

/**
 * Hook that queries the organization theme.
 * @param organizationId The organization ID.
 * @returns The organization theme data.
 */
export function useOrganizationThemeQuery(organizationId: OrganizationId) {
  const { data } = useQuery(['organizations', organizationId, 'theme'], () =>
    organizations.getOrganizationTheme(organizationId)
  );

  return {
    organizationTheme: data!,
  };
}

/**
 * Hook that returns a mutation used to set an organization's theme.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useSetOrganizationThemeMutation(organizationId: OrganizationId) {
  return useMutation(
    (payload: SetOrganizationThemePayload) =>
      organizations.setOrganizationTheme(organizationId, payload),
    {}
  );
}
