import { createStitches } from '@stitches/react';
import type * as Stitches from '@stitches/react';

export type { VariantProps, ComponentProps } from '@stitches/react';

export const { createTheme, css, getCssText, globalCss, styled, theme, config } = createStitches({
  theme: {
    logo: {
      uri: 'logo192.png',
    },
    colors: {
      'primary-100': '#BBDEFBFF',
      'primary-200': '#90CAF9FF',
      'primary-300': '#64B5F6FF',
      'primary-400': '#42A5F5FF',
      'primary-500': '#2196F3FF',
      'primary-600': '#1E88E5FF',
      'primary-700': '#1976D2FF',
      'primary-800': '#1565C0FF',
      'primary-900': '#0D47A1FF',
      'primary-contrast': '#ffffffff',

      'danger-50': '#fef2f2ff',
      'danger-100': '#fee2e2ff',
      'danger-200': '#fecacaff',
      'danger-300': '#fca5a5ff',
      'danger-400': '#f87171ff',
      'danger-500': '#ef4444ff',
      'danger-600': '#dc2626ff',
      'danger-700': '#b91c1cff',
      'danger-800': '#991b1bff',
      'danger-900': '#7f1d1dff',
      'danger-contrast': '#ffffffff',

      'warning-50': '#fff7edff',
      'warning-100': '#ffedd5ff',
      'warning-200': '#fed7aaff',
      'warning-300': '#fdba74ff',
      'warning-400': '#fb923cff',
      'warning-500': '#f97316ff',
      'warning-600': '#ea580cff',
      'warning-700': '#c2410cff',
      'warning-800': '#9a3412ff',
      'warning-900': '#7c2d12ff',
      'warning-contrast': '#ffffffff',

      'success-50': '#f0fdf4ff',
      'success-100': '#dcfce7ff',
      'success-200': '#bbf7d0ff',
      'success-300': '#86efacff',
      'success-400': '#4ade80ff',
      'success-500': '#22c55eff',
      'success-600': '#16a34aff',
      'success-700': '#15803dff',
      'success-800': '#166534ff',
      'success-900': '#14532dff',
      'success-contrast': '#ffffffff',

      'neutral-0': '#FFFFFF',
      'neutral-50': '#F8FAFC',
      'neutral-100': '#F1F5F9',
      'neutral-200': '#E2E8F0',
      'neutral-300': '#CBD5E1',
      'neutral-400': '#94A3B8',
      'neutral-500': '#64748B',
      'neutral-600': '#475569',
      'neutral-700': '#334155',
      'neutral-800': '#1E293B',
      'neutral-900': '#0F172A',

      // 'neutral-50': 'hsl(206 20% 98.8%)',
      // 'neutral-100': 'hsl(206 14% 96.0%)',
      // 'neutral-200': 'hsl(206 13% 93.7%)',
      // 'neutral-300': 'hsl(206 12% 92.0%)',
      // 'neutral-400': 'hsl(206 12% 89.5%)',
      // 'neutral-500': 'hsl(206 11% 85.2%)',
      // 'neutral-600': 'hsl(206 10% 80.0%)',
      // 'neutral-700': 'hsl(206 6% 56.1%)',
      // 'neutral-800': 'hsl(206 6% 43.9%)',
      // 'neutral-9000': 'hsl(206 12% 7%)',

      // 'neutral-50': '#f9fafb',
      // 'neutral-100': '#f3f4f6',
      // 'neutral-200': '#e5e7eb',
      // 'neutral-300': '#d1d5db',
      // 'neutral-400': '#9ca3af',
      // 'neutral-500': '#6b7280',
      // 'neutral-600': '#4b5563',
      // 'neutral-700': '#374151',
      // 'neutral-800': '#1f2937',
      // 'neutral-900': '#111827',

      // Decisio green - taken from BMA logo
      // 'information-50': '#5f9a97',
      // 'information-100': '#5c9894',
      // 'information-200': '#55918d',
      // 'information-300': '#4e8a86',
      // 'information-400': '#407c78',
      // 'information-500': '#326e6b',  // BMA logo colour
      // 'information-600': '#004744',
      // 'information-700': '#084d4b',
      // 'information-800': '#135451',
      // 'information-900': '#195856',
      // 'information-contrast': '#ffffff',

      // Decisio blue - taken from Decisio icon
      'information-50': '#6bc4ff',
      'information-100': '#68c1ff',
      'information-200': '#5ebaff',
      'information-300': '#54b3ff',
      'information-400': '#3ea4ff',
      'information-500': '#2196F3', // Decisio icon colour
      'information-600': '#006dc5',
      'information-700': '#0074cc',
      'information-800': '#007ad4',
      'information-900': '#007fda',
      'information-contrast': '#ffffff',

      'global-bg': 'hsl(218, 31%, 95%)',
      'global-text': '#334155',

      'navbar-bg': '$primary-700',
      'navbar-text': '$neutral-0',
    },
    space: {
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '3rem',
      12: '3.5rem',
    },
    sizes: {
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '3rem',
      12: '3.5rem',
      13: '3.75rem',
      14: '4rem',
      20: '6rem',
      30: '9rem',
    },
    fontSizes: {
      xs: '0.75rem',
      sm: '0.875rem',
      default: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
    },
    letterSpacings: {
      xs: '0.04em',
      sm: '0.015em',
      default: '0em',
      lg: '0em',
      xl: '0em',
      '2xl': '-.0215em',
      '3xl': '-.0115em',
      '4xl': '-.015em',
      '5xl': '-.015em',
    },
    lineHeights: {
      none: 1,
      tight: 1.25,
      snug: 1.375,
      normal: 1.5,
      relaxed: 1.625,
      loose: 2,
      3: '.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',

      // Match fontSizes
      xs: '1rem',
      sm: '1.25rem',
      default: '1.25rem',
      lg: '1.3rem',
      xl: '1.75rem',
      '2xl': '2rem',
      '3xl': '1.4',
      '4xl': '1.45',
      '5xl': '1.5',
    },
    fontWeights: {
      hairline: '100',
      thin: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    radii: {
      none: '0',
      sm: '0.125rem',
      default: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      round: '50%',
      pill: '9999px',
    },
    zIndices: {
      1: '800',
      2: '900',
      3: '1000',
      4: '1100',
      max: '9999',
    },
    shadows: {
      xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
      sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    },
    containers: {
      sm: '540px',
      md: '720px',
      lg: '960px',
      xl: '1200px',
      '2xl': '1400px',
    },
  },
  media: {
    sm: '(min-width: 576px)',
    md: '(min-width: 768pc)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
    '2xl': '(min-width: 1400px)',
    motion: '(prefers-reduced-motion)',
    hover: '(any-hover: hover)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',
  },
  utils: {
    p: (value: any) => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: (value: any) => ({
      paddingTop: value,
    }),
    pr: (value: any) => ({
      paddingRight: value,
    }),
    pb: (value: any) => ({
      paddingBottom: value,
    }),
    pl: (value: any) => ({
      paddingLeft: value,
    }),
    px: (value: any) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: any) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: (value: any) => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: (value: any) => ({
      marginTop: value,
    }),
    mr: (value: any) => ({
      marginRight: value,
    }),
    mb: (value: any) => ({
      marginBottom: value,
    }),
    ml: (value: any) => ({
      marginLeft: value,
    }),
    mx: (value: any) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: any) => ({
      marginTop: value,
      marginBottom: value,
    }),

    ta: (value: any) => ({ textAlign: value }),

    fd: (value: any) => ({ flexDirection: value }),
    fw: (value: any) => ({ flexWrap: value }),

    ai: (value: any) => ({ alignItems: value }),
    ac: (value: any) => ({ alignContent: value }),
    jc: (value: any) => ({ justifyContent: value }),
    as: (value: any) => ({ alignSelf: value }),
    fg: (value: any) => ({ flexGrow: value }),
    fs: (value: any) => ({ flexShrink: value }),
    fb: (value: any) => ({ flexBasis: value }),

    bc: (value: any) => ({
      backgroundColor: value,
    }),

    br: (value: any) => ({
      borderRadius: value,
    }),
    btrr: (value: any) => ({
      borderTopRightRadius: value,
    }),
    bbrr: (value: any) => ({
      borderBottomRightRadius: value,
    }),
    bblr: (value: any) => ({
      borderBottomLeftRadius: value,
    }),
    btlr: (value: any) => ({
      borderTopLeftRadius: value,
    }),

    bs: (value: any) => ({ boxShadow: value }),

    lh: (value: any) => ({ lineHeight: value }),

    ox: (value: any) => ({ overflowX: value }),
    oy: (value: any) => ({ overflowY: value }),

    pe: (value: any) => ({ pointerEvents: value }),
    us: (value: any) => ({ WebkitUserSelect: value, userSelect: value }),

    size: (value: any) => ({
      width: value,
      height: value,
    }),

    linearGradient: (value: any) => ({
      backgroundImage: `linear-gradient(${value})`,
    }),

    appearance: (value: any) => ({
      WebkitAppearance: value,
      appearance: value,
    }),
    userSelect: (value: any) => ({
      WebkitUserSelect: value,
      userSelect: value,
    }),
    backgroundClip: (value: any) => ({
      WebkitBackgroundClip: value,
      backgroundClip: value,
    }),
  },
});

export type CSS = Stitches.CSS<typeof config>;
export type ScaleValue<Scale> = Stitches.ScaleValue<Scale, typeof config>;
