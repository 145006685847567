import { useLicense } from 'app';

import {
  BaseLicensingAlert,
  BaseLicensingAlertProps,
  LicensingAlertProps,
} from './BaseLicensingAlert';

export function LicensedApplicationCountReachedAlert(
  props: {
    applicationCount: number;
  } & Omit<LicensingAlertProps, keyof BaseLicensingAlertProps>
) {
  const { applicationCount, ...rest } = props;
  const { isLicensed, licenseDetails } = useLicense();

  if (!isLicensed || applicationCount < licenseDetails.maximumApplications) {
    return null;
  }

  return (
    <BaseLicensingAlert
      colorScheme="danger"
      childrenForAll={
        <>
          Application creation is disabled because the number of licensed applications has been
          reached.&nbsp;
        </>
      }
      {...rest}
    ></BaseLicensingAlert>
  );
}
