import { ReactNode } from 'react';

import {
  AccordionPanelContextProvider,
  UseAccordionPanelProviderOpts,
  useAccordionPanelContextProvider,
} from './AccordionContext';

export type AccordionPanelProps = UseAccordionPanelProviderOpts & {
  children?: ReactNode;
};

/**
 * Accordion panel component.
 * @param props The accordion panel props.
 */
export function AccordionPanel(props: AccordionPanelProps) {
  const { children } = props;

  const context = useAccordionPanelContextProvider(props);

  return <AccordionPanelContextProvider value={context}>{children}</AccordionPanelContextProvider>;
}
