import { useQuery } from 'react-query';

import { getDataViews } from 'api/data-views';
import { getGeoDatasets } from 'api/geodatasets';
import { getMimicBoards } from 'api/mimic-boards';
import { getPipelineDefinitions } from 'api/pipelines';
import { getReports } from 'api/reports';
import {
  ApplicationId,
  DataView,
  GeoDataset,
  MimicBoard,
  PipelineDefinitionPreview,
  Report,
  Resource,
  ResourceEntity,
  ResourceType,
} from 'interfaces';

export function useResourcesQuery(applicationIds: ApplicationId[]) {
  // TODO: use `useQueries` when it supports Suspense: https://github.com/tannerlinsley/react-query/pull/2109
  const { data } = useQuery(['resources', { applicationIds }], async () => {
    let allDataViews: DataView[] = [];
    let allMimicBoards: MimicBoard[] = [];
    let allReports: Report[] = [];
    let allMaps: GeoDataset[] = [];
    let allPipelines: PipelineDefinitionPreview[] = [];

    for (var applicationId of applicationIds) {
      const [dataViews, mimicBoards, reports, maps, pipelines] = await Promise.all([
        getDataViews(applicationId),
        getMimicBoards(applicationId),
        getReports(applicationId),
        getGeoDatasets(applicationId),
        getPipelineDefinitions(applicationId),
      ]);

      allDataViews = [...allDataViews, ...dataViews];
      allMimicBoards = [...allMimicBoards, ...mimicBoards];
      allReports = [...allReports, ...reports];
      allMaps = [...allMaps, ...maps];
      allPipelines = [...allPipelines, ...pipelines];
    }

    return Object.fromEntries<ResourceEntity[]>([
      ['DataView', allDataViews],
      ['Report', allReports],
      ['MimicBoard', allMimicBoards],
      ['Map', allMaps],
      ['Pipeline', allPipelines],
    ]);
  });

  const resourceTypes = Object.keys(data!) as ResourceType[];
  const resources = resourceTypes.reduce<Resource[]>((acc, type) => {
    return [
      ...acc,
      ...data![type].map((x) => ({
        type,
        resourceId: x.id,
        name: x.name,
        label: x.label,
        iconUri: x.iconUri,
        categoryId: x.categoryId,
        applicationId: x.applicationId,
      })),
    ];
  }, []);

  return {
    resourceTypes,
    resources,
  };
}
