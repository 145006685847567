import { ReactChild, forwardRef, useRef } from 'react';

import {
  FieldHint,
  FieldLabel,
  FormField,
  FormFieldContextConsumer,
  FormFieldErrors,
} from 'components/form-field';
import { useMergedRefs } from 'hooks';

import { Select, SelectProps } from './Select';

export type SelectFieldProps = SelectProps & {
  /**
   * The field label content.
   */
  label?: ReactChild;

  /**
   * Whether the field is required.
   * @default false
   */
  required?: boolean;

  /**
   * Whether the field is disabled.
   * @default false
   */
  disabled?: boolean;

  /**
   * The field hint.
   */
  hint?: ReactChild;

  /**
   * The field error.
   */
  error?: string | string[] | boolean | null;

  /**
   * Additional field CSS.
   */
  css?: any;
};

export const SelectField = forwardRef<HTMLDivElement, SelectFieldProps>(function SelectField(
  props,
  forwardedRef
) {
  const { label, required, hint, error, css, disabled, ...rest } = props;

  const controlRef = useRef<HTMLDivElement>();
  const handleRef = useMergedRefs(controlRef, forwardedRef);

  const handleLabelClick = () => {
    controlRef.current?.focus();
  };

  return (
    <FormField required={required} error={error} disabled={disabled} css={css}>
      {!!label && <FieldLabel onClick={handleLabelClick}>{label}</FieldLabel>}

      <FormFieldContextConsumer>
        {({ invalid }) => (
          <Select
            ref={handleRef}
            state={invalid ? 'error' : undefined}
            disabled={disabled}
            {...rest}
          />
        )}
      </FormFieldContextConsumer>

      {!!hint && <FieldHint>{hint}</FieldHint>}
      <FormFieldErrors />
    </FormField>
  );
});
