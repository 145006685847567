import { ApplicationId, ScenarioId } from 'interfaces';

import {
  BlobStorageItem,
  CreateGeoDatasetPayload,
  GeoDataset,
  GeoDatasetDataLayerType,
  GeoDatasetExternalLayer,
  GeoDatasetExternalLayerJsonData,
  GeoDatasetId,
  GeoDatasetInternalLayer,
  GeoDatasetMap,
  GeoDatasetPropertiesResult,
  LayerGeoJsonData,
  UpdateGeoDatasetPayload,
} from 'interfaces/geodataset';

import { api } from './api';

export function getGeoDataset(applicationId: ApplicationId, geoDataSetId: GeoDatasetId) {
  return api.get<GeoDataset>(`/applications/${applicationId}/geodatasets/${geoDataSetId}`, {
    params: {
      applicationId,
      geoDataSetId,
    },
  });
}

export function getGeoDatasetMap(
  applicationId: ApplicationId,
  geoDataSetId: GeoDatasetId,
  scenarioId: ScenarioId
) {
  return api.get<GeoDatasetMap>(
    `/applications/${applicationId}/geodatasets/${geoDataSetId}/map/${scenarioId}`,
    {
      params: {
        applicationId,
        scenarioId,
      },
    }
  );
}

export function getGeoDatasets(applicationId: ApplicationId) {
  return api.get<GeoDataset[]>(`/applications/${applicationId}/geodatasets`, {
    params: {
      applicationId,
    },
  });
}

export function getGeoDatasetDataLayerTypes(applicationId: ApplicationId) {
  return api.get<GeoDatasetDataLayerType[]>(`/applications/${applicationId}/geodatasets/types`, {
    params: {
      applicationId,
    },
  });
}

/**
 * Creates a new geo dataset.
 * @param applicationId The application ID.
 * @param geoDatasetId The geo dataset ID.
 */
export function createGeoDataset(applicationId: ApplicationId, payload: CreateGeoDatasetPayload) {
  payload.geoDatasetInternalLayers?.forEach((value: GeoDatasetInternalLayer) => {
    if (typeof value.layerSettings != 'string')
      value.layerSettings = JSON.stringify(value.layerSettings);
  });
  payload.geoDatasetExternalLayers?.forEach((value: GeoDatasetExternalLayer) => {
    if (typeof value.layerSettings != 'string')
      value.layerSettings = JSON.stringify(value.layerSettings);
  });
  return api.post<GeoDataset>(`/applications/${applicationId}/geodatasets`, payload);
}

/**
 * Updates a geo dataset.
 * @param moduleId The module ID.
 * @param payload The request payload used to update the geo dataset.
 * @return A `Promise` of the updated payload.
 */
export function updateGeoDataset(
  applicationId: ApplicationId,
  geoDatasetId: GeoDatasetId,
  payload: UpdateGeoDatasetPayload
): Promise<GeoDataset> {
  payload.geoDatasetInternalLayers?.forEach((value: GeoDatasetInternalLayer) => {
    if (typeof value.layerSettings != 'string')
      value.layerSettings = JSON.stringify(value.layerSettings);
  });
  payload.geoDatasetExternalLayers?.forEach((value: GeoDatasetExternalLayer) => {
    if (typeof value.layerSettings != 'string')
      value.layerSettings = JSON.stringify(value.layerSettings);
  });

  return api.put<GeoDataset>(`/applications/${applicationId}/geodatasets/${geoDatasetId}`, payload);
}

/**
 * Deletes an existing geo dataset belonging to the specified application.
 * @param applicationId The application ID.
 * @param geoDatasetId The geo dataset ID.
 */
export function deleteGeoDataset(applicationId: ApplicationId, geoDatasetId: GeoDatasetId) {
  return api.delete(`/applications/${applicationId}/geodatasets/${geoDatasetId}`);
}

/**
 * Calculates the centroid data for a geo dataset belonging to the specified application.
 * @param applicationId The application ID.
 * @param geoDatasetId The geo dataset ID.
 */
export function calculateGeoDatasetCentroidData(
  applicationId: ApplicationId,
  geoDatasetId: GeoDatasetId
) {
  return api.get<GeoDatasetPropertiesResult>(
    `/applications/${applicationId}/geodatasets/${geoDatasetId}/centroid`
  );
}

/**
 * Gets the json data for a geo dataset layer belonging to the specified application.
 * @param applicationId The application ID.
 * @param isFileBlob The type of the file data being used.
 * @param layerUriOrBlobName The geo dataset layer uri or blob name.
 */
export async function getLayerGeoJsonData(
  applicationId: ApplicationId,
  isFileBlob: boolean,
  layerUriOrBlobName: string
) {
  return api.get<LayerGeoJsonData>(`/applications/${applicationId}/geodatasets/layer-json-data`, {
    params: {
      isFileBlob,
      layerUriOrBlobName,
    },
  });
}

/**
 * Gets the geo dataset blob container content belonging to the specified application.
 * @param applicationId The application ID.
 */
export function getGeoDatasetBlobContainerContent(applicationId: ApplicationId) {
  return api.get<BlobStorageItem[]>(
    `/applications/${applicationId}/geodatasets/blob-container-content`
  );
}

/**
 * Gets the geo dataset external layers json data belonging to the specified application.
 * @param applicationId The application ID.
 * @param geoDatasetId The Geodataset ID.
 */
export async function getGeoDatasetExternalLayersJsonData(
  applicationId: ApplicationId,
  geoDatasetId: GeoDatasetId
) {
  return await api.get<GeoDatasetExternalLayerJsonData[]>(
    `/applications/${applicationId}/geodatasets/${geoDatasetId}/layers-json-data`
  );
}
