import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as licenseApi from 'api/license';

const licenseQueryKey = 'license' as const;
const licenseRequestCodeQueryKey = 'license-request-code' as const;
const licenseUsageRequestQueryKey = 'license-usage' as const;

const oneHourInMilliseconds = 24 * 60 * 60 * 1000;

/**
 * Hook for returning license details.
 * @returns The license details with query states.
 */
export function useLicenseDetailsQuery() {
  const { data, ...queryInfo } = useQuery(licenseQueryKey, () => licenseApi.getLicenseDetails(), {
    staleTime: 24 * oneHourInMilliseconds,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });

  return {
    licenseDetails: data!,
    ...queryInfo,
  };
}

/**
 * Hook that returns a new license request code.
 * @returns The license request code with query states.
 */
export function useLicenseRequestCodeQuery() {
  const { data, ...queryInfo } = useQuery(
    licenseRequestCodeQueryKey,
    () => licenseApi.getLicenseRequestCode(),
    {
      staleTime: 24 * oneHourInMilliseconds,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    licenseRequestCode: data!,
    ...queryInfo,
  };
}

/**
 * Hook that returns license usage details.
 * @returns The license usage details with query states.
 */
export function useLicenseUsageQuery() {
  const { data, ...queryInfo } = useQuery(
    licenseUsageRequestQueryKey,
    () => licenseApi.getLicenseUsage(),
    {
      staleTime: oneHourInMilliseconds,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    licenseUsage: data!,
    ...queryInfo,
  };
}

/**
 * Hook for registering and applying a license key.
 * @returns The `UseMutationResult`.
 */
export function useLicenseRegisterMutation() {
  const queryClient = useQueryClient();

  return useMutation((licenseKey: string) => licenseApi.registerLicense(licenseKey), {
    onSuccess: (licenseWasRegistered) => {
      if (licenseWasRegistered) {
        queryClient.invalidateQueries(licenseQueryKey);
        queryClient.invalidateQueries(licenseRequestCodeQueryKey);
        queryClient.invalidateQueries(licenseUsageRequestQueryKey);
      }
    },
  });
}
