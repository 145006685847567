import { useLicense } from 'app';

import { Link } from 'components/link';

import {
  BaseLicensingAlert,
  BaseLicensingAlertProps,
  LICENSING_PATH,
  LicensingAlertProps,
} from './BaseLicensingAlert';

export function LicenseExpiredAlert(
  props: Omit<LicensingAlertProps, keyof BaseLicensingAlertProps>
) {
  const { isLicensed } = useLicense();

  if (isLicensed) {
    return null;
  }

  return (
    <BaseLicensingAlert
      colorScheme="danger"
      childrenForAll={<>Some functionality is disabled because Decisio is not licensed.&nbsp;</>}
      childrenForAdmin={
        <>
          <Link to={LICENSING_PATH}>Request a license</Link>.
        </>
      }
      {...props}
    ></BaseLicensingAlert>
  );
}
